import { useEffect, useState, useRef } from 'react'
import { isArray, isStr, isFunc } from '../services/isType'
import useConfirmInput from '../hooks/useConfirmInput'

import { useDispatch, useSelector } from 'react-redux'
import * as NotificationAction from '../actions/notificationAction'

function ConfirmInputModal(){
	const dispatch = useDispatch()
	const ConfirmInput = useConfirmInput()


	const ref = useRef(null)
	useEffect(() => {
		if(ref.current) ConfirmInput.setRef(ref)
	}, [ref])


	const inputRef = useRef(null)
	useEffect(() => {
		if(inputRef.current) ConfirmInput.setInputRef(inputRef)
	}, [inputRef])


	const onClose = useSelector(state => state.confirmInput.onClose)
	const closeHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()
		if(isFunc(onClose)) onClose()
	}


	const showed = useSelector(state => state.confirmInput.showed)
	const defaultValue = useSelector(state => state.confirmInput.defaultValue)
	const [value, setValue] = useState('')
	const [lastShowed, setLastShowed] = useState(false)
	useEffect(() => {
		if(showed && !lastShowed){
			// Установить значение поля при открытии
			if(isStr(defaultValue)) editHandler(defaultValue)
			else editHandler('')
		}

		setLastShowed(showed)
	}, [showed])


	const onSubmit = useSelector(state => state.confirmInput.onSubmit)
	const submitHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		if(valid !== true){
			dispatch(NotificationAction.show(valid))
			return;
		}

		if(isFunc(onSubmit)) onSubmit(value)
	}


	const type = useSelector(state => state.confirmInput.type)
	let inputmode
	switch(type){
	case 'positive-amount':
		inputmode = 'decimal'
		break
	default:
		inputmode = 'text'
	}


	const validators = useSelector(state => state.confirmInput.validators)
	const [valid, setValid] = useState(true)
	const editHandler = newValue => {
		if(!inputRef.current) return;

		if(!isStr(newValue) && inputRef.current) newValue = inputRef.current.value
		else if(!isStr(newValue)) newValue = ''

		if(type === 'positive-amount' && newValue.trim() !== ''){
			const parseAmount = /^([0-9]+)(\.[0-9]{0,2})?$/.exec(newValue.replace(/,/ig, '.'))
			if(!parseAmount) newValue = value
			else{
				let beforeDot = parseInt(parseAmount[1])
				if(!isFinite(beforeDot)) beforeDot = 0

				let afterDot = parseAmount[2]
				if(!isStr(afterDot)) afterDot = ''

				newValue = `${beforeDot}${afterDot}`
			}
		}

		setValue(newValue)

		let newValid = true
		if(isArray(validators)){
			for(let validator of validators){
				const validation = validator(newValue)
				if(validation !== true){
					newValid = validation
					break
				}
			}
		}
		if(newValid !== valid) setValid(newValid)
	}


	const title = useSelector(state => state.confirmInput.title)
	const description = useSelector(state => state.confirmInput.description)
	const placeholder = useSelector(state => state.confirmInput.placeholder)


	return (
		<form ref={ref} className={`modal confirm-input-modal ${showed ? 'show' : ''} ${valid !== true ? 'confirm-input-invalid' : ''}`} onSubmit={submitHandler}>
			<div>
				<div className='modal-content'>
					<div className='confirm-title'>{title}</div>
					{(() => {
						if(description && typeof description === 'object' && description.type === 'div') return description
						else if(isStr(description)) return <div className='confirm-text'>{description}</div>
					})()}
					<div className='confirm-input__field'>
						<input ref={inputRef} type='text' inputMode={inputmode} placeholder={placeholder} value={value} onInput={editHandler} />
						<button className='btn blue' type='submit' onClick={submitHandler}>OK</button>
					</div>
					<div className='confirm-cancel' onClick={closeHandler}>Cancel</div>
				</div>
			</div>
		</form>
	)

}
export default ConfirmInputModal