import types from '../actions/types'

const defaultState = {
    text: null,
    type: null,
    dateClose: null
}

function notification(state=defaultState, action) {    
    // For Debugger
    console.log('payload:', action);

    switch (action.type) {
        case types.NOTIFICATION_SET:
            return {
                ...state,
                text: action.payload.text,
                type: action.payload.type,
                dateClose: action.payload.dateClose
            }
        default:
            return state
    }
}

export default notification