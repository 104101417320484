import { useState } from 'react'
import TelegramPage from '../../components/TelegramPage'
import TelegramButton from '../../components/TelegramButton'
import Num from '../../services/Num'
import WebAPI from '../../WebAPI'

import { useSelector, useDispatch } from 'react-redux'
import * as ErrorAction from '../../actions/errorAction'
import * as PageAction from '../../actions/pageAction'
import * as UserAction from '../../actions/userAction'

function WithdrawConfirm(){
	const withdrawOrder = useSelector(state => state.user.withdrawOrder)
	const dispatch = useDispatch()

	const withdrawMethods = {
		'usdt_trc20': 'USDT TRC-20'
	}

	const submitHandler = () => {
		try{
			setSendingForm(true)

			WebAPI.User.withdraw(withdrawOrder)
			.then(() => {
				return WebAPI.User.get()
			})
			.then(user => {
				setSendingForm(false)

				if(!user || user.object !== 'user') throw { type: 'invalid_response' }
				dispatch(UserAction.setAccount(user))
				dispatch(PageAction.setAccountPage('withdraw_success'))
			})
			.catch(e => {
				setSendingForm(false)
				dispatch(ErrorAction.sendError(e))
			})
		}catch(e){
			dispatch(ErrorAction.sendError(e))
		}
	}


	const [sendingForm, setSendingForm] = useState(false)


	return (
		<TelegramPage className='page-withdraw-confirm' bgColor='#000' back={() => dispatch(PageAction.setAccountPage('balance'))}>
			<div className='withdraw-confirm-title h24'>Confirm withdraw order</div>
			<div className='data-list'>
				<div className='data-list-line'>
					<div>Amount</div>
					<div>{Num.amount(withdrawOrder.amount)} USD</div>
				</div>
				<div className='data-list-line'>
					<div>Method</div>
					<div>{withdrawMethods[withdrawOrder.method]}</div>
				</div>
				{withdrawOrder.method === 'usdt_trc20' ? (
					<>
						<div className='data-list-line'>
							<div>Wallet</div>
							<div className='withdraw-confirm-wallet'>{withdrawOrder.tron_wallet}</div>
						</div>
						<div className='data-list-line'>
							<div>Commission</div>
							<div>2 USDT</div>
						</div>
						<div className='data-list-line'>
							<div>You will receive</div>
							<div className='withdraw-confirm-receive'>{Num.amount(withdrawOrder.amount - 2)} USDT</div>
						</div>
					</>
				) : null}
			</div>
			<div className='withdraw-confirm-description t14'>After placing an order, you will be unable to change the details</div>
			<div className='withdraw-confirm-cancel' onClick={() => dispatch(PageAction.setAccountPage('balance'))}>Cancel order</div>
			<TelegramButton text='CONFIRM' onClick={() => submitHandler()} loading={sendingForm} />
		</TelegramPage>
	)

}

export default WithdrawConfirm