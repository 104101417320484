import { useDispatch } from 'react-redux'
import * as ConfirmAction from '../actions/confirmAction'

function useConfirm(){
	const dispatch = useDispatch()

	const show = (title, text) => {
		return new Promise((resolve, reject) => {
			const confirmHandler = () => {
				dispatch(ConfirmAction.hide())
				resolve()
			}
			const cancelHandler = () => {
				dispatch(ConfirmAction.hide())
				reject()
			}
			dispatch(ConfirmAction.show(title, text, confirmHandler, cancelHandler))
		})
	}

	return { show }

}
export default useConfirm