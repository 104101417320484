import { useState, useRef, useEffect } from 'react'
import { isFunc, isStr } from '../../services/isType'
import TelegramPage from '../../components/TelegramPage'
import Input from '../../components/Input'
import TelegramButton from '../../components/TelegramButton'
import Validators from '../../services/Validators'
import WebAPI from '../../WebAPI'

import { useDispatch } from 'react-redux'
import * as ErrorAction from '../../actions/errorAction'
import * as UserAction from '../../actions/userAction'
import * as NotificationAction from '../../actions/notificationAction'

function Registration(){
	const dispatch = useDispatch()

	const firstNameRef = useRef(null)
	const [ firstNameValue, setFirstNameValue ] = useState('')
	const [ firstNameValid, setFirstNameValid ] = useState(true)

	const lastNameRef = useRef(null)
	const [ lastNameValue, setLastNameValue ] = useState('')
	const [ lastNameValid, setLastNameValid ] = useState(true)

	const emailRef = useRef(null)
	const [ emailValue, setEmailValue ] = useState('')
	const [ emailValid, setEmailValid ] = useState(true)

	const blurFields = () => {
		if(firstNameRef.current) firstNameRef.current.blur()
		if(lastNameRef.current) lastNameRef.current.blur()
		if(emailRef.current) emailRef.current.blur()
	}


	const [canSubmitForm, setCanSubmitForm] = useState(true)
	const [sendingForm, setSendingForm] = useState(false)
	useEffect(() => {
		if(
			firstNameValid !== true ||
			lastNameValid !== true ||
			emailValid !== true
		) setCanSubmitForm(false)
		else setCanSubmitForm(true)
	}, [firstNameValid, lastNameValid, emailValid])


	const submitHandler = (e) => {
		try{
			if(e && isFunc(e.preventDefault)) e.preventDefault()

			if(firstNameValid !== true){
				blurFields()
				if(firstNameRef.current) firstNameRef.current.focus()
				dispatch(NotificationAction.show(firstNameValid))
			}else if(lastNameValid !== true){
				blurFields()
				if(lastNameRef.current) lastNameRef.current.focus()
				dispatch(NotificationAction.show(lastNameValid))
			}else if(emailValid !== true){
				blurFields()
				if(emailRef.current) emailRef.current.focus()
				dispatch(NotificationAction.show(emailValid))
			}else{
				blurFields()
				setSendingForm(true)

				WebAPI.User.update(firstNameValue, lastNameValue, emailValue)
				.then(user => {
					setSendingForm(false)
					if(!user || user.object !== 'user') throw { type: 'invalid_response' }
					dispatch(UserAction.setAccount(user))
				})
				.catch(e => {
					setSendingForm(false)
					if(e && e.type == 'invalid_input_data' && e.fields) dispatch(NotificationAction.show(Object.values(e.fields).join('. ')))
					else if(e && e.type == 'invalid_input_data' && isStr(e.description)) dispatch(NotificationAction.show(e.description))
					else throw e
				})
			}
		}catch(e){
			alert(JSON.stringify(e))
			dispatch(ErrorAction.sendError(e))
		}
	}

	return (
		<TelegramPage className='page-registartion' bgColor='#000'>
			<div className='registration-title h24'>Personal information</div>
			<div className='registration-description t16'>Only one step left to issue cards</div>
			<form className='form' onSubmit={submitHandler}>
				<Input ref={firstNameRef} name='first_name' label='First name *' placeholder='Required' noHelpers
					value={firstNameValue} onEdit={setFirstNameValue}
					valid={firstNameValid} onValid={setFirstNameValid}
					validators={[
						Validators.required('First name is required'),
						Validators.name('Only English letters can be used in first name')
					]}
				/>
				<Input ref={lastNameRef} name='last_name' label='Last name *' placeholder='Required' noHelpers
					value={lastNameValue} onEdit={setLastNameValue}
					valid={lastNameValid} onValid={setLastNameValid}
					validators={[
						Validators.required('Last name is required'),
						Validators.name('Only English letters can be used in last name')
					]}
				/>
				<Input ref={emailRef} name='email' label='Email *' placeholder='email@example.com' type='email' noHelpers
					value={emailValue} onEdit={setEmailValue}
					valid={emailValid} onValid={setEmailValid}
					validators={[
						Validators.required('Email is required'),
						Validators.email('Enter a valid email address')
					]}
				/>
				<TelegramButton text={canSubmitForm ? 'CONTINUE' : 'NEXT'} onClick={submitHandler} loading={sendingForm ? true : false} />
			</form>
			<div className='registration-agreement t14'>By providing personal information, you agree to our terms of use and privacy policy</div>
			<div className='registration-links t14'>
				<div>Terms of use</div>
				<div>Privacy policy</div>
			</div>
		</TelegramPage>
	)
}


export default Registration