import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import AddLibrary from './services/AddLibrary'

import { Provider } from 'react-redux'
import initStore from './store'

import './assets/styles.css'
import './assets/fonts/SF Pro Display/stylesheet.css'
import './assets/fonts/Roboto Mono/stylesheet.css'

const store = initStore()

AddLibrary('https://telegram.org/js/telegram-web-app.js')
document.querySelector('meta[name=viewport]').content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)