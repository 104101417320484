import { isFunc } from '../services/isType'
import Color from '../services/Color'
import TG from '../TG'

import types from '../actions/types'

const defaultState = {
    showed: false,
    title: '',
    text: '',
    onConfirm: null,
    onCancel: null,

    pageBg: null,
    pageBackState: null
}

function confirm(state=defaultState, action) {    
    // For Debugger
    console.log('payload:', action);

    switch (action.type) {
        case types.CONFIRM_SHOW:
            const nowBg = TG.getBgColor()
            const nowBackState = TG.BackButton.getState()

            const newBg = new Color(nowBg)
            newBg.layerBlack(0.6)
            TG.setBgColor(newBg.getHex())
            TG.BackButton.show(isFunc(action.payload.onCancel) ? action.payload.onCancel : () => {})

            document.body.style.overflow = 'hidden'

            return {
                ...state,
                showed: true,
                title: action.payload.title,
                text: action.payload.text,
                onConfirm: action.payload.onConfirm,
                onCancel: action.payload.onCancel,

                pageBg: nowBg,
                pageBackState: nowBackState
            }
        case types.CONFIRM_HIDE:
            if(state.pageBg) TG.setBgColor(state.pageBg)
            if(state.pageBackState) TG.BackButton(state.pageBackState)

            document.body.style.overflow = 'auto'
        
            return {
                ...state,
                showed: false,

                pageBg: null,
                pageBackState: null
            }
        default:
            return state
    }
}

export default confirm