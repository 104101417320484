const emailValidator = require('email-validator')

const Validators = {

	name: (errorText) => {
		return function name(value){
			if(!/^[a-z\s]+$/ig.test(value.trim())) return errorText
			return true
		}
	},

	email: (errorText) => {
		return function email(value){
			if(!emailValidator.validate(value)) return errorText
			return true
		}
	},

	required: (errorText) => {
		return function required(value){
			if(!value) return errorText
			if(value.trim() === '') return errorText
			return true
		}
	},

	amount: (errorText) => {
		return function amount(value){
			value = parseFloat(value)
			if(!isFinite(value) || typeof value !== 'number') return errorText
			return true
		}
	},

	maxLength: (length, errorText) => {
		return function maxLength(value){
			if(typeof value !== 'string') throw new Error('Invalid input of validator: maxLength')
			if(value.length > length) return errorText
			return true
		}
	},

	max: (amount, errorText) => {
		return function max(value){
			if(typeof value === 'string' && value.trim().length === 0) return true

			value = parseFloat(value)
			if(!isFinite(value)) return errorText
			if(value > amount) return errorText
			return true
		}
	},

	min: (amount, errorText) => {
		return function min(value){
			value = parseFloat(value)
			if(!isFinite(value)) return errorText
			if(value < amount) return errorText
			return true
		}
	},

	tronWallet: (errorText) => {
		return function tronWallet(value){
			if(!/^T[a-z0-9]{33}$/gi.test(value.trim())) return errorText
			return true
		}
	}

}

export default Validators