import types from '../actions/types'
import * as PageAction from '../actions/pageAction'
import * as UserAction from '../actions/userAction'
import * as ErrorAction from '../actions/errorAction'

import TG from '../TG'
import WebAPI from '../WebAPI'

/*
// actions
const auth = async (state, dispatch, action) => {
	if(state.user.inited) return;

	if(!TG.isVersion('7.0')) return dispatch(ErrorAction.sendError({ type: 'version_not_supported' }))
	
	try{
		const initData = TG.getInstance()
		const session = await WebAPI.User.auth(initData)
		if(session.object !== 'session') throw { type: 'invalid_response' }

		WebAPI.User.setToken(session.token)
		dispatch(UserAction.setSession(session))
	}catch(e){
		dispatch(ErrorAction.sendError(e))
	}
}

const update = async (state, dispatch, action) => {
	try{
		const account = await WebAPI.User.update(action.payload)
		//alert(JSON.stringify(account))
		if(account.object !== 'user') throw { type: 'invalid_response' }
		dispatch(UserAction.setAccount(account))
	}catch(e){
		if(e && typeof e === 'object' && e.type === 'invalid_input_data') throw e
		dispatch(ErrorAction.sendError(e))
	}
}

const getDepositWallet = async (state, dispatch, action) => {
	try{
		const paymentMethod = action.payload
		if(paymentMethod === 'usdt_trc20'){
			const wallet = await WebAPI.Tron.getWallet()
			//alert(JSON.stringify(wallet))
			if(wallet.object !== 'tron_wallet') throw { type: 'invalid_response' }
			dispatch(UserAction.updatedDepositWallet(wallet))
			dispatch(PageAction.showAccountPage('deposit_wallet'))
		}else throw new Error('Invalid deposit method')
	}catch(e){
		if(e && typeof e === 'object' && e.type === 'invalid_input_data') throw e
		dispatch(ErrorAction.sendError(e))
	}
}*/




const UserMiddleware = store => next => action => {
	
	switch (action.type) {
		/*case types.USER_AUTH:
			auth(store.getState(), store.dispatch, action)
			next(UserAction.setInitialization())
			break;

		case types.USER_UPDATE:
			return update(store.getState(), store.dispatch, action)

		case types.USER_GET_DEPOSIT_WALLET:
			return getDepositWallet(store.getState(), store.dispatch, action)*/

		default:
			return next(action)
	}

}

export default UserMiddleware