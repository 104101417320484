import { useSelector } from 'react-redux'

import { ReactComponent as CoinSvg } from '../assets/img/coin.svg'

function AccountCard(){
	const account = useSelector(state => state.user.account)

	return (
		<div className='staking-card'>
			<div className='staking-card__ico'><CoinSvg /></div>
			<div className='staking-card__data'>
				<div>Staking</div>
				<div>Coming soon...</div>
			</div>
			<div className='staking-card__income'>
				<div>+0.00 USD</div>
				<div>per day</div>
			</div>
		</div>
	)

}

export default AccountCard