import React, { Component } from 'react'
import AfterLoadWindow from '../services/AfterLoadWindow'

import Notification from '../components/Notification'
import ConfirmModal from '../components/ConfirmModal'
import ConfirmInputModal from '../components/ConfirmInputModal'
import AlertModal from '../components/AlertModal'
import LoaderModal from '../components/LoaderModal'
import Balance from './account/Balance'
import Account from './Account'
import ErrorPage from './ErrorPage'
import Splashscreen from './Splashscreen'

import {connect} from 'react-redux'
import * as UserAction from '../actions/userAction'
import * as ErrorAction from '../actions/errorAction'

class App extends Component {
	componentDidCatch(error, errorInfo){
		console.log('error')
		console.log(error, errorInfo)
		this.props.sendError(error)
		alert('_app_catch')

		const pageLoaded = () => {

		}
		//AfterLoadWindow(pageLoaded)
	}

	render() {
		/*return <>
			<Cabinet />
			<Notification />
		</>*/
		return (
			<>
				{
					this.props.hasError ? <ErrorPage /> :
					this.props.authorized ? <Account /> :
					<Splashscreen />
				}
				<Notification />
				<ConfirmModal />
				<ConfirmInputModal />
				<AlertModal />
				<LoaderModal />
			</>
		)
    }
}

const stateToProps = (state) => {
	return {
        authorized: state.user.authorized,
        hasError: state.error.hasError
    }
}
const dispatchToProps = (dispatch) => {
	return {
		sendError: (e) => {
        	dispatch(ErrorAction.sendError(e))
        }
	}
}

export default connect(stateToProps, dispatchToProps)(App)