import { useSelector } from 'react-redux'

function LoaderModal(){
	const showed = useSelector(state => state.loader.showed)
	const success = useSelector(state => state.loader.success)


	return (
		<div className={`modal loader-modal ${showed ? 'show' : ''} ${success ? 'success' : ''}`}>
			<div>
				<div className='modal-content'><div></div></div>
			</div>
		</div>
	)

}
export default LoaderModal