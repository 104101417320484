import { isFunc } from './services/isType'

let TG = function(){
	let that = this,
		WebApp = null

	let inited = false
	let init = () => {
		if(inited) return;
		inited = true

		WebApp = window.Telegram.WebApp
		WebApp.ready()
		WebApp.expand()
	}

	that.getInstance = () => {
		init()
		return WebApp.initData
	}

	const isVersion = (version) => {
		init()
		return WebApp.isVersionAtLeast(version)
	}
	that.isVersion = isVersion


	let BackButton = function(){
		let setState = state => {
			if(state.showed) show(state.handler)
			else hide()
		}
		let that = setState

		let nowHandler = null
		let showed = false

		const removeHandler = () => {
			init()
			if(!isFunc(nowHandler)) return;

			WebApp.BackButton.offClick(nowHandler)
			nowHandler = null
		}
		const setHandler = handler => {
			removeHandler()

			if(typeof handler !== 'function') return;
			nowHandler = handler
			WebApp.BackButton.onClick(handler)
		}

		let show = handler => {
			showed = true

			init()
			if(!isVersion('6.2')) return;

			setHandler(handler)
			WebApp.BackButton.show()
		}
		that.show = show

		let hide = () => {
			showed = false

			init()
			if(!isVersion('6.2')) return;
			
			WebApp.BackButton.hide()
		}
		that.hide = hide

		that.getState = () => {
			return { showed, handler:nowHandler }
		}

		return that
	}
	that.BackButton = BackButton()


	let MainButton = function(){
		let that = this
		let nowHandler = null

		let removeHandler = () => {
			if(typeof nowHandler !== 'function') return;
			WebApp.MainButton.offClick(nowHandler)
			nowHandler = null
		}
		that.show = () => {
			init()
			if(!isVersion('6.0')) return;

			removeHandler()
			WebApp.MainButton.show()
			that.active()
		}
		that.handler = handler => {
			init()
			if(!isVersion('6.0')) return;

			removeHandler()
			if(typeof handler == 'function'){
				WebApp.MainButton.onClick(handler)
				nowHandler = handler
			}
		}
		that.text = text => {
			init()
			if(!isVersion('6.0')) return;

			WebApp.MainButton.text = text
		}
		that.hide = () => {
			init()
			if(!isVersion('6.0')) return;

			removeHandler()
			WebApp.MainButton.hide()
		}

		that.disable = () => {
			init()
			if(!isVersion('6.0')) return;

			WebApp.MainButton.disable()
			WebApp.MainButton.color = '#2f2f2f'
			WebApp.MainButton.textColor = '#626262'
		}
		that.loading = () => {
			init()
			if(!isVersion('6.0')) return;

			that.disable()
			WebApp.MainButton.showProgress()
		}
		that.active = () => {
			init()
			if(!isVersion('6.0')) return;

			WebApp.MainButton.enable()
			WebApp.MainButton.hideProgress()
			WebApp.MainButton.color = '#4bb7ef'
			WebApp.MainButton.textColor = '#fff'
		}
	}
	MainButton = new MainButton()
	that.MainButton = MainButton


	that.vibration = () => {
		init()
		if(!isVersion('6.2')) return;
		WebApp.HapticFeedback.notificationOccurred('error')
	}

	that.getViewHeight = () => {
		init()
		return WebApp.viewportStableHeight
	}

	that.onEvent = (eventType, eventHandler) => {
		init()
		WebApp.onEvent(eventType, eventHandler)
	}
	that.offEvent = (eventType, eventHandler) => {
		init()
		WebApp.offEvent(eventType, eventHandler)
	}


	let nowBgColor = null
	that.setBgColor = (bgColor) => {
		if(!bgColor) return;
		init()

		nowBgColor = bgColor
		WebApp.setHeaderColor(bgColor)
		WebApp.setBackgroundColor(bgColor)
	}
	that.getBgColor = () => {
		return nowBgColor
	}

	that.scanQr = (title) => {
		return new Promise((resolve, reject) => {
			init()
			if(!isVersion('6.4')) return resolve('')

			WebApp.showScanQrPopup({ text: title }, text => {
				resolve(text)
				return true
			})
		})
	}

	that.close = () => {
		init()
		WebApp.close()
	}

	that.test = () => {
		init()
		alert(JSON.stringify(WebApp.themeParams))
	}

}

TG = new TG()
export default TG