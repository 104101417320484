const types = {
    PAGE_MOUNTED: 'PAGE_MOUNTED',
    SET_ACCOUNT_PAGE: 'SET_ACCOUNT_PAGE',
    SET_SHOWED_CARD: 'SET_SHOWED_CARD',

    USER_START_AUTH: 'USER_START_AUTH',
    USER_SET_ACCOUNT: 'USER_SET_ACCOUNT',
    USER_SET_DEPOSIT_WALLET: 'USER_SET_DEPOSIT_WALLET',
    USER_SET_WITHDRAW_ORDER: 'USER_SET_WITHDRAW_ORDER',
    USER_SET_CARDS_LIMIT_AMOUNT_NOTIFIED: 'USER_SET_CARDS_LIMIT_AMOUNT_NOTIFIED',

    NOTIFICATION_SET: 'NOTIFICATION_SET',

    ERROR_CATCH: 'ERROR_CATCH',

    CONFIRM_SHOW: 'CONFIRM_SHOW',
    CONFIRM_HIDE: 'CONFIRM_HIDE',

    CONFIRM_INPUT_SHOW: 'CONFIRM_INPUT_SHOW',
    CONFIRM_INPUT_HIDE: 'CONFIRM_INPUT_HIDE',
    CONFIRM_INPUT_REF: 'CONFIRM_INPUT_REF',
    CONFIRM_INPUT_FIELD_REF: 'CONFIRM_INPUT_FIELD_REF',

    ALERT_SHOW: 'ALERT_SHOW',
    ALERT_HIDE: 'ALERT_HIDE',

    LOADER_SHOW: 'LOADER_SHOW',
    LOADER_SUCCESS: 'LOADER_SUCCESS',
    LOADER_HIDE: 'LOADER_HIDE'
}

export default types