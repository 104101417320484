import { Children, useRef, useEffect } from 'react'
import { isFunc, isStr, isArray } from '../services/isType'

import { ReactComponent as ArrowsSwitchSvg } from '../assets/img/arrows-switch.svg'
import { ReactComponent as CheckMarkSvg } from '../assets/img/checkmark.svg'

function Select({ name, label, children, defaultValue, validators,  className, value, onEdit, valid, onValid, showed, onShowed }){
	const selectRef = useRef(null)


	const editHandler = newValue => {
		onEdit(newValue)

		let newValid = true
		if(isArray(validators)){
			for(let validator of validators){
				let isValid = validator(newValue)
				if(isValid !== true){
					newValid = isValid
					break;
				}
			}
		}
		onValid(newValid)
	}


	useEffect(() => {
		if(value === '' && defaultValue && isStr(defaultValue)) editHandler(defaultValue)
		else editHandler(value)
	}, [])


	useEffect(() => {
		const domClickHandler = (e) => {
			if(!selectRef.current) return;
			const target = e.target

			const parentSelect = target.closest('.select')
			const isSelect = (target.classList.contains('select') && target === selectRef.current) || (parentSelect && parentSelect === selectRef.current)
			if(isSelect){
				if(e && isFunc(e.preventDefault)) e.preventDefault()

				const parentModal = target.closest('.select-modal')
				const isModal = target.classList.contains('select-modal') || parentModal
				if(isModal){

					const parentOption = target.closest('.select-modal-option')
					const isOption = target.classList.contains('select-modal-option') || parentOption
					if(isOption){

						let option
						if(target.classList.contains('select-modal-option')) option = target
						else option = parentOption

						editHandler(option.getAttribute('data-value'))
						onShowed(false)
					}

				}else if(showed) onShowed(false)
				else if(!showed) onShowed(true)

			}else if(showed) onShowed(false)
		}
		document.addEventListener('click', domClickHandler)

		return () => {
			document.removeEventListener('click', domClickHandler)
		}
	}, [showed])
	

	let selectedText = 'Not selected'
	if(value !== null){
		let findOption = false

		Children.map(children, child => {
			if(findOption) return;
			if(child.props.value === value){
				selectedText = child.props.content
				findOption = true
			}
		})
	}

	return (
		<div ref={selectRef} className={`select ${className}`}>
			<div>
				<div className='select-label'>{label}</div>
				<div className='select-selected'>{selectedText}<ArrowsSwitchSvg /></div>
				<div className={`select-modal${showed ? ' select-modal__show' : ''}`}>
					{Children.map(children, child => {
						const optionValue = child.props.value
						const optionContent = child.props.content

						return (
							<div key={optionValue} data-value={optionValue} className={`select-modal-option${value === optionValue ? ' select-modal-option__selected' : ''}`}>
								<CheckMarkSvg />
								<div>{optionContent}</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default Select