import types from './types'

export const show = () => {
    return {
        type: types.LOADER_SHOW
    }
}
export const success = () => {
    return {
        type: types.LOADER_SUCCESS
    }
}
export const hide = () => {
    return {
        type: types.LOADER_HIDE
    }
}