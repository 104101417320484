let AfterLoadWindow = function(){
	let loaded = false,
		queue = []

	window.addEventListener('load', () => {
		loaded = true
		for(let task of queue) task()
	})

	return (task) => {
		if(!task || typeof task != 'function') return;
		if(loaded) task()
		else queue.push(task)
	}
}

AfterLoadWindow = new AfterLoadWindow()
export default AfterLoadWindow