import DateTime from '../../../services/DateTime'
import Num from '../../../services/Num'

function TransactionLine({ data }){

	let key = 'loading'
	const className = ['transaction-line']
	let title = 'loading loading load'
	let description = 'loading loading'
	let amount = 'loading'
	let status = 'loading load'

	if(data === 'loading') className.push('transaction-line__loading')
	else if(data && data.type === 'withdraw'){
		key = data.id
		className.push('transaction-line__withdraw')
		description = (new DateTime(data.date)).toBeautifulStr()
		amount = `-${Num.amount(data.withdraw.amount)} USD`

		if(data.withdraw.status === 'wait'){
			className.push('transaction-line__wait')
			status = 'Processing'
		}

		if(data.withdraw.method === 'usdt_trc20') title = 'Withdraw to: ' + data.withdraw.tron_wallet.substr(0, 3) + '...' + data.withdraw.tron_wallet.substr(-4)
	}
	else if(data && data.type === 'deposit'){
		key = data.id
		className.push('transaction-line__deposit')
		description = (new DateTime(data.date)).toBeautifulStr()
		amount = `+${Num.amount(data.deposit.amount)} USD`

		if(data.deposit.status === 'success'){
			className.push('transaction-line__success')
			status = 'Received'
		}

		if(data.deposit.method === 'usdt_trc20') title = 'Top up USDT (TRC-20)'
	}
	else if(data && data.type === 'bank_card_transaction'){
		key = data.id
		className.push('transaction-line__card-transaction')
		description = (new DateTime(data.date)).toBeautifulStr()
		amount = `-${Num.amount(data.bank_card_transaction.amount)} USD`

		if(data.bank_card_transaction.status === 'processing'){
			className.push('transaction-line__amount-red')
			status = 'Processing'
		}
		if(data.bank_card_transaction.status === 'paid'){
			className.push('transaction-line__amount-red')
			status = 'Successfull'
		}

		title = `Card #${data.bank_card_transaction.card_last4}`
	}
	else throw new Error('Invalid transaction data')

	return (
		<div key={key} className={className.join(' ')}>
			<div className='transaction-line__ico'></div>
			<div className='transaction-line__center'>
				<div className='transaction-line__title'>{title}</div>
				<div className='transaction-line__description'>{description}</div>
			</div>
			<div className='transaction-line__right'>
				<div className='transaction-line__amount'>{amount}</div>
				<div className='transaction-line__status'>{status}</div>
			</div>
		</div>
	)

}

export default TransactionLine