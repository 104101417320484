import CardLine from './line'

function CardsLoading(){

	return (
		<>
			<CardLine data='loading' />
			<CardLine data='loading' />
			<CardLine data='loading' />
			<CardLine data='loading' />
		</>
	)

}

export default CardsLoading