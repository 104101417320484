import { useDispatch, useSelector } from 'react-redux'
import * as ConfirmInputAction from '../actions/confirmInputAction'

function useConfirmInput(){
	const dispatch = useDispatch()
	const modalRef = useSelector(state => state.confirmInput.ref)
	const inputRef = useSelector(state => state.confirmInput.inputRef)

	const show = ({ type, title, description, placeholder, validators, defaultValue }) => {
		return new Promise((resolve, reject) => {
			const submitHandler = value => {
				dispatch(ConfirmInputAction.hide())
				resolve(value)
			}
			const closeHandler = () => {
				dispatch(ConfirmInputAction.hide())
				reject()
			}

			type = (['positive-amount'].includes(type) ? type : 'text')
			dispatch(ConfirmInputAction.show({ type, title, description, placeholder, validators, defaultValue, onSubmit:submitHandler, onClose:closeHandler }))
		})
	}

	const setRef = ref => {
		dispatch(ConfirmInputAction.ref(ref))
	}

	const setInputRef = ref => {
		dispatch(ConfirmInputAction.inputRef(ref))
	}

	return { show, setRef, setInputRef }

}
export default useConfirmInput