import { useState, useEffect, useRef } from 'react'
import { isFunc } from '../../services/isType'
import TelegramPage from '../../components/TelegramPage'
import TelegramButton from '../../components/TelegramButton'
import TelegramCryptoWalletField from '../../components/TelegramCryptoWalletField'
import AmountField from '../../components/AmountField'
import Select from '../../components/Select'
import SelectOption from '../../components/SelectOption'
import Validators from '../../services/Validators'

import { useSelector, useDispatch } from 'react-redux'
import * as PageAction from '../../actions/pageAction'
import * as UserAction from '../../actions/userAction'
import * as NotificationAction from '../../actions/notificationAction'

function Withdraw(){
	const dispatch = useDispatch()
	const balance = useSelector(state => parseFloat(state.user.account.balance))
	

	const amountRef = useRef(null)
	const [amountValue, setAmountValue] = useState('')
	const [amountValid, setAmountValid] = useState(true)

	const [methodValue, setMethodValue] = useState('')
	const [methodValid, setMethodValid] = useState(true)
	const [methodShowed, setMethodShowed] = useState(false)

	const tronWalletRef = useRef(null)
	const [tronWalletValue, setTronWalletValue] = useState('')
	const [tronWalletValid, setTronWalletValid] = useState(true)

	const blurFields = () => {
		if(amountRef.current) amountRef.current.blur()
		setMethodShowed(false)
		if(tronWalletRef.current) tronWalletRef.current.blur()
	}


	const [canSubmit, setCanSubmit] = useState(true)
	useEffect(() => {
		if(
			amountValid !== true ||
			methodValid !== true ||
			tronWalletValid !== true
		){
			setCanSubmit(false)
		}else setCanSubmit(true)
	}, [amountValid, methodValid, tronWalletValid])

	const submitHandler = (e) => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		if(amountValid !== true){
			blurFields()
			if(amountRef.current) amountRef.current.focus()
			dispatch(NotificationAction.show(amountValid))
		}else if(methodValid !== true){
			blurFields()
			setMethodShowed(true)
			dispatch(NotificationAction.show(methodValid))
		}else if(methodValue === 'usdt_trc20' && tronWalletValid !== true){
			blurFields()
			if(tronWalletRef.current) tronWalletRef.current.focus()
			dispatch(NotificationAction.show(tronWalletValid))
		}else if(parseFloat(amountValue) < 100){
			blurFields()
			if(amountRef.current) amountRef.current.focus()
			dispatch(NotificationAction.show('The minimum withdrawal amount is $100'))
		}else{
			const order = {
				amount: parseFloat(amountValue),
				method: methodValue
			}

			if(methodValue === 'usdt_trc20') order.tron_wallet = tronWalletValue

			dispatch(UserAction.setWithdrawOrder(order))
			dispatch(PageAction.setAccountPage('withdraw_confirm'))
		}
	}

	return (
		<TelegramPage className='page-withdraw' bgColor='#000' back={() => dispatch(PageAction.setAccountPage('balance'))}>
			<div className='withdraw-title h24'>Withdraw balance</div>
			<form className='form' onSubmit={submitHandler}>
				<AmountField ref={amountRef} name='amount' label='Enter amount' currency='USD' amountMax={balance}
					value={amountValue} onEdit={setAmountValue}
					valid={amountValid} onValid={setAmountValid}
					validators={[
						Validators.required('Withdraw amount is required'),
						Validators.amount('Enter the correct withdrawal amount'),
						Validators.max(balance, 'There are not enough funds on your balance')
					]}
				/>
				<Select name='method' label='Withdraw method' defaultValue='usdt_trc20'
					value={methodValue} onEdit={setMethodValue}
					valid={methodValid} onValid={setMethodValid}
					showed={methodShowed} onShowed={setMethodShowed}
					validators={[
						Validators.required('Withdraw method is required')
					]}
				>
					<SelectOption value='usdt_trc20' content='USDT (TRC-20)' />
				</Select>
				{methodValue === 'usdt_trc20' ? (
					<TelegramCryptoWalletField ref={tronWalletRef} name='wallet' placeholder='Enter your USDT (TRC-20) wallet address'
						value={tronWalletValue} onEdit={setTronWalletValue}
						valid={tronWalletValid} onValid={setTronWalletValid}
						validators={[
							Validators.required('USDT (TRC-20) address is required'),
							Validators.tronWallet('Enter correct USDT (TRC-20) address')
						]}
					/>
				) : null}
				<TelegramButton text={canSubmit ? 'MAKE ORDER' : 'NEXT'} onClick={submitHandler} />
			</form>
		</TelegramPage>
	)

}

export default Withdraw