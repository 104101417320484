import { useDispatch } from 'react-redux'
import * as PageAction from '../actions/pageAction'

function AccountBalance({ balance }){
	const dispatch = useDispatch()

	let beforeDot = parseInt(balance),
		afterDot = parseInt(parseInt(balance * 100) % 100) + ''
	if(afterDot.length < 2) afterDot = '0' + afterDot

	return (
		<div className='account-balance'>
			<div className='account-balance__before-dot'>{beforeDot},</div>
			<div className='account-balance__after-dot'>{afterDot}</div>
			<div className='account-balance__currency'>$</div>
		</div>
	)

}

export default AccountBalance