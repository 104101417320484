function LineField({ label, value, className }){

	return (
		<div className={`field-line ${className}`}>
			<div>
				<div>{label}</div>
				<div className='field-line__value'>{value}</div>
			</div>
		</div>
	)

}

export default LineField