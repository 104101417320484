import { useEffect, useState } from 'react'
import { isFunc } from '../services/isType'
import AfterLoadWindow from '../services/AfterLoadWindow'
import TG from '../TG'

function TelegramButton({ text, loading, onClick }){

	const init = () => {
		TG.MainButton.show()
	}
	useEffect(() => {
		AfterLoadWindow(init)

		return () => TG.MainButton.hide()
	}, [])

	
	const clickHandler = () => {
		if(onClick && isFunc(onClick)) onClick()
	}
	const setClickHandler = () => {
		TG.MainButton.handler(clickHandler)
	}
	useEffect(() => AfterLoadWindow(setClickHandler), [onClick])
	

	const update = () => {
		TG.MainButton.text(text)

		if(loading === true) TG.MainButton.loading()
		else TG.MainButton.active()
	}
	useEffect(() => AfterLoadWindow(update), [text, loading])
}

export default TelegramButton