import { useState, useRef, useEffect } from 'react'
import { isFunc } from '../services/isType'

function Warning({ text, onClose }){
	const ref = useRef(null)
	
	const [startClose, setStartClose] = useState(false)
	const [animClose, setAnimClose] = useState(false)
	const [hide, setHide] = useState(false)
	const closeHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()
		setStartClose(true)
	}
	useEffect(() => {
		if(startClose) setTimeout(() => setAnimClose(true), 400)
	}, [startClose])
	useEffect(() => {
		if(animClose){
			if(ref.current && isFunc(ref.current.animate)){
				const size = ref.current.getBoundingClientRect()
				ref.current.animate({
					height: [size.height, 0],
					maxHeight: [size.height, 0]
				}, { duration:200, fill: 'both' })

				setTimeout(() => setHide(true), 200)
			}else setHide(true)
		}
	}, [animClose])
	useEffect(() => {
		if(hide){
			setStartClose(false)
			setAnimClose(false)
			if(isFunc(onClose)) onClose()
		}
	}, [hide])


	return (
		<div ref={ref} className={`warning ${startClose ? 'warning__fadeout' : ''} ${hide ? 'warning__hide' : ''}`}>
			<div>
				<div className='warning-ico'></div>
				<div className='warning-text'>{text}</div>
				<div className='warning-close' onClick={closeHandler}></div>
			</div>
		</div>
	)

}

export default Warning