import { useEffect, useState } from 'react'
import TelegramPage from '../../components/TelegramPage'
import WalletQrCode from '../../components/WalletQrCode'
import useCopy from '../../hooks/useCopy'
import WebAPI from '../../WebAPI'

import { useSelector, useDispatch } from 'react-redux'
import * as PageAction from '../../actions/pageAction'
import * as ErrorAction from '../../actions/errorAction'
import * as UserAction from '../../actions/userAction'


function DepositWallet(){
	const dispatch = useDispatch()
	const depositWallet = useSelector(state => state.user.depositWallet)
	const Copy = useCopy()
	//alert(JSON.stringify(depositWallet))

	const generated = depositWallet.status === 'ready' ? true : false
	const address = depositWallet.status === 'ready' ? depositWallet.address : 'Generating...'


	const updateWallet = () => {

		if(depositWallet.object === 'tron_wallet' && !generated){
			WebAPI.Tron.getWallet()
			.then(wallet => {
				if(wallet.object !== 'tron_wallet') throw { type: 'invalid_response' }
				dispatch(UserAction.setDepositWallet(wallet))
			})
			.catch(e => {
				dispatch(ErrorAction.sendError(e))
			})
		}
		else if(depositWallet.object === 'tron_wallet' && generated){

		}
		else throw dispatch(ErrorAction.sendError({ type: 'error_text', text: 'Invalid wallet type' }))

	}
	useEffect(() => {
		setTimeout(updateWallet, 3000)
	}, [depositWallet])


	return (
		<TelegramPage className='page-deposit-wallet' bgColor='#1c1c1c' back={() => dispatch(PageAction.setAccountPage('balance'))}>
			<div className='deposit-wallet-title h24'>Top up</div>
			<div className='deposit-wallet-container'>
				<WalletQrCode generated={generated} address={address} />
				<div className='deposit-wallet-address-name'>Your USDT (TRC-20) wallet</div>
				<div className='deposit-wallet-address' onClick={() => generated === true && Copy(address, 'The USDT (TRC-20) address has been copied')}>{address}</div>
			</div>
			<div className='deposit-wallet-notification-1'>You can send any amount. But if you transfer an amount less than <b>100 USDT</b>, a <b>5 USDT</b> fee will be charged</div>
			<div className='deposit-wallet-notification-2'>Send USDT only on the TRON network, otherwise you may lose the sent assets without the possibility of refund</div>
		</TelegramPage>
	)

}

export default DepositWallet