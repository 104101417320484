import { cardsList, useRef } from 'react'
import { isStr, isFunc } from '../../../services/isType'
import useAlert from '../../../hooks/useAlert'
import TelegramPage from '../../../components/TelegramPage'
import DataList from '../../../components/DataList'
import Warning from '../../../components/Warning'
import CardsEmpty from './empty'
import CardLine from './line'
import CardsLoading from './loading'
import WebAPI from '../../../WebAPI'

import { useDispatch, useSelector } from 'react-redux'
import * as UserAction from '../../../actions/userAction'
import * as NotificationAction from '../../../actions/notificationAction'
import * as PageAction from '../../../actions/pageAction'
import * as ErrorAction from '../../../actions/errorAction'

import { ReactComponent as CardSvg } from '../../../assets/img/card.svg'

function Cards(){
	const Alert = useAlert()

	const dispatch = useDispatch()
	const balance = useSelector(state => state.user.account.balance)
	const limit = useSelector(state => state.user.account.cards_limit)
	const maked = useSelector(state => state.user.account.cards_in_this_month)

	const limitAmount = useSelector(state => state.user.account.cards_limit_amount)
	const limitAmountNotified = useSelector(state => state.user.cardsLimitAmountNotified)

	let canMakeCards = limit - maked
	if(canMakeCards < 0) canMakeCards = 0

	const cardsList = useRef(null)

	const getCards = async () => {
		try{
			const cards = await WebAPI.Cards.get()
			if(cards.object !== 'list') throw new Error('Invalid cards list')

			return cards.list
		}catch(e){
			if(e && e.type === 'invalid_input_data' && isStr(e.description)) Alert(e.description)
            else dispatch(ErrorAction.sendError(e))
        }
	}


	const closeLimitAmountNotify = () => {
		dispatch(UserAction.setCardsLimitAmountNotified(limitAmount))
	}


	return (
		<TelegramPage className='page-cards' bgColor='#1c1c1c' back={() => dispatch(PageAction.setAccountPage('balance'))}>
			{balance < limitAmount && limitAmount !== limitAmountNotified ? <Warning text={`The minimum balance for issuing cards is ${limitAmount}$`} onClose={closeLimitAmountNotify} /> : null}
			<div className='cards-title h24'>Virtual cards</div>
			<div className='btn logo-left blue' onClick={e => {
				e.preventDefault()
				dispatch(PageAction.setAccountPage('cards_issue'))
			}}>
				<CardSvg />
				<div>Issue card</div>
			</div>
			<div className='cards-stats'>
				<div className='cards-stat'>
					<div>
						<div className='cards-stat__amount'>0.00 <span>USD</span></div>
						<div className='cards-stat__title'>Spent</div>
						<div className='cards-stat__description'>in April 2024</div>
					</div>
				</div>
				<div className='cards-stat'>
					<div>
						<div className='cards-stat__amount'>0.00 <span>USD</span></div>
						<div className='cards-stat__title'>Cashback</div>
						<div className='cards-stat__description'>accrued on the 1st of each month</div>
					</div>
				</div>
				<div className='cards-stat'>
					<div>
						<div className='cards-stat__limit-top'>
							<div className='cards-stat__limit-title'>Limit on cards</div>
							<div className='cards-stat__limit-free'>Available {canMakeCards} card{canMakeCards === 1 ? '' : 's'}</div>
						</div>
						<div className='cards-stat__limit-description'>
							<div>{limit} card{limit === 1 ? '' : 's'} per month</div>
							<div className='cards-stat__limit-bar'><div style={{ width:`${canMakeCards*100/limit}%` }}></div></div>
						</div>
					</div>
				</div>
			</div>
			<div className='page-label'>Your cards</div>
			<div className='cards-list'>
				<DataList
					ref={cardsList}
                    request={page => getCards()}
                    line={<CardLine />}
                    loading={<CardsLoading />}
                    empty={<CardsEmpty />}
                />
			</div>
		</TelegramPage>
	)
	/*
<DataList
				request={getCards}
				
			/>
	*/

}

export default Cards