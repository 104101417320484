import TransactionLine from './line'

function TransactionsLoading(){
	return (
		<>
			<TransactionLine data='loading' />
			<TransactionLine data='loading' />
			<TransactionLine data='loading' />
			<TransactionLine data='loading' />
		</>
	)
}

export default TransactionsLoading