import { useState, useRef, useEffect, forwardRef } from 'react'
import { isFunc, isStr, isArray } from '../services/isType'
import TG from '../TG'

import { ReactComponent as ScanQrSvg } from '../assets/img/scan-qr.svg'

const TelegramCryptoWalletField = forwardRef(({ name, placeholder, validators, value, onEdit, valid, onValid }, inputRef) => {
	
	const editHandler = newValue => {
		if(!isStr(newValue)) newValue = inputRef.current.value

		if(!/^[a-z0-9]*$/ig.test(newValue)) return;
		onEdit(newValue)

		let newValid = true
		if(isArray(validators)){
			for(let validator of validators){
				let isValid = validator(newValue)
				if(isValid !== true){
					newValid = isValid
					break;
				}
			}
		}
		onValid(newValid)
	}
	useEffect(editHandler, [])


	const clearHandler = (e) => {
		e.preventDefault()
		editHandler('')
		if(inputRef.current) inputRef.current.focus()
	}
	const scanWalletHandler = async (e) => {
		e.preventDefault()
		const qrCodeText = await TG.scanQr('USDT (TRC-20) address')
		editHandler(qrCodeText)
		if(inputRef.current) inputRef.current.blur()
	}


	const maskRef = useRef(null)
	const [maskHeight, setMaskHeight] = useState(0)
	useEffect(() => {
		if(!maskRef.current) return;
		setMaskHeight(maskRef.current.getBoundingClientRect().height)
	}, [value, maskRef])

	return (
		<div className={`crypto-wallet${valid !== true ? ' field-invalid' : ''}`}>
			<div>
				<textarea ref={inputRef} id={name} placeholder={placeholder} value={value} onInput={editHandler} style={{ height: `${maskHeight}px` }}></textarea>
				{name && placeholder ? <div ref={maskRef} className='crypto-wallet__mask'>{value.length ? value : placeholder}</div> : null}
				<div onClick={clearHandler} className='crypto-wallet__clear'>Clear</div>
				<div onClick={scanWalletHandler} className='crypto-wallet__qr'><ScanQrSvg /></div>
			</div>
		</div>
	)

})

export default TelegramCryptoWalletField