import types from '../actions/types'

const defaultState = {
    hasError: false,
    errorText: ''
}

function error(state = defaultState, action) {
    
    switch (action.type) {
        case types.ERROR_CATCH:
            let error = action.payload,
                errorText = ''
            //alert(error.constructor.name)
            alert(error && typeof error === 'object' && ['TypeError'].includes(error.constructor.name) ? error.message : JSON.stringify(error))

            errorText = 'App was crashed'
            if(error && error instanceof Object){
                if(error.constructor.name === 'Error') errorText = error.message
                else if(error.type === 'version_not_supported') errorText = 'Your application version is not supported, update Telegram and try again'
                else if(error.type === 'invalid_response') errorText = 'Invalid Server response'
                else if(error.type === 'error_text') errorText = error.text
            }

            return {
                ...state,
                hasError: true,
                errorText: errorText
            }
        default:
            return state
    }

}

export default error