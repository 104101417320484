import { useSelector, useDispatch } from 'react-redux'
import * as PageAction from '../actions/pageAction'

import { ReactComponent as UserLogoSvg } from '../assets/img/logo-user.svg'

function AccountCard(){
	const dispatch = useDispatch()
	const account = useSelector(state => state.user.account)

	return (
		<div className='account-card' onClick={() => dispatch(PageAction.setAccountPage('cabinet'))}>
			<div className='account-card__ico'><UserLogoSvg /></div>
			<div className='account-card__data'>
				<div>{account.first_name} {account.last_name}</div>
				<div>{account.email}</div>
			</div>
			<div className='account-card__arrow'></div>
		</div>
	)

}

export default AccountCard