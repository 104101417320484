class DateTime{

	_dateTime = null

	constructor(dateTimeMix=null){
		if(dateTimeMix == null) this._dateTime = new Date()
		else if(dateTimeMix && dateTimeMix instanceof Object && dateTimeMix.constructor.name === 'Date') this._dateTime = dateTimeMix
		else if(DateTime.isValidDateTimeStr(dateTimeMix)) this._dateTime = DateTime.parseDateTimeStr(dateTimeMix)
		else throw new Error("Invalid dateTimeMix format")
	}

	toStr = () => DateTime.toStr(this._dateTime)
	toBeautifulStr = () => DateTime.toBeautifulStr(this._dateTime)

	modify = modify => {
		let parse = modify.match(/^([+-]{1})?([0-9]+)\s(second|minute|hour|day|month|year)[e]?[s]?$/)
		if(!parse) throw new Error('dateTime.modify: Invalid modify string')

		let count = (parse[1] === '-' ? -1 : 1) * parseInt(parse[2])

		switch(parse[3]){
		case 'second':
			this._dateTime.setUTCSeconds(parseInt(this._dateTime.getUTCSeconds()) + count)
			break
		case 'minute':
			this._dateTime.setUTCMinutes(parseInt(this._dateTime.getUTCMinutes()) + count)
			break
		case 'hour':
			this._dateTime.setUTCHours(parseInt(this._dateTime.getUTCHours()) + count)
			break
		case 'day':
			this._dateTime.setUTCDate(parseInt(this._dateTime.getUTCDate()) + count)
			break
		case 'month':
			this._dateTime.setUTCMonth(parseInt(this._dateTime.getUTCMonth()) + count)
			break
		case 'year':
			this._dateTime.setUTCFullYear(parseInt(this._dateTime.getUTCFullYear()) + count)
			break
		default:
			throw new Error('Invalid modificator')
		}
	}

	bigThen = dateTime => {
		return this._dateTime > dateTime._dateTime
	}
	smallThen = dateTime => {
		return this._dateTime < dateTime._dateTime
	}




	/* STATIC METHODS */
	static now = () => {
		return new DateTime()
	}

	static fromTimestampMs = timestampMs => {
		return new DateTime(new Date(parseInt(timestampMs)))
	}

	static fromTimestamp = timestamp => {
		return this.fromTimestampMs(parseInt(timestamp) * 1000)
	}

	static isValidDateTimeStr = dateTimeStr => {
		try{
			if(!dateTimeStr || typeof dateTimeStr !== "string") throw false

			let parse = dateTimeStr.match(/^([0-9]{4})\-([0-9]{2})\-([0-9]{2})\s([0-9]{2})\:([0-9]{2})\:([0-9]{2})$/)
			if(parse){
				let dateTime = new Date()
				dateTime.setUTCFullYear(parse[1], parseInt(parse[2]-1), parse[3])
				dateTime.setUTCHours(parse[4], parse[5], parse[6], 0)

				if(DateTime.toStr(dateTime) === dateTimeStr) return true
			}

			throw false
		}catch(e){
			return false
		}
	}

	
	static parseDateTimeStr = dateTimeStr => {
		let parse = dateTimeStr.match(/^([0-9]{4})\-([0-9]{2})\-([0-9]{2})\s([0-9]{2})\:([0-9]{2})\:([0-9]{2})$/)
		if(!parse) throw new Error("DateTime.parseDateTimeStr: Invalid dateTimeStr format")

		let date = new Date()
		date.setUTCFullYear(parse[1], parseInt(parse[2]-1), parse[3])
		date.setUTCHours(parse[4], parse[5], parse[6], 0)
		return date
	}


	static toStr = dateTime => {
		let y = dateTime.getUTCFullYear() + '',
			m = (parseInt(dateTime.getUTCMonth()) + 1) + '',
			d = dateTime.getUTCDate() + '',
			h = dateTime.getUTCHours() + '',
			i = dateTime.getUTCMinutes() + '',
			s = dateTime.getUTCSeconds() + ''
		if(m.length < 2) m = '0' + m
		if(d.length < 2) d = '0' + d
		if(h.length < 2) h = '0' + h
		if(i.length < 2) i = '0' + i
		if(s.length < 2) s = '0' + s

		return y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s
	}


	static toBeautifulStr = dateTime => {
		const monthes = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
		let y = dateTime.getUTCFullYear(),
			m = dateTime.getUTCMonth(),
			d = dateTime.getUTCDate(),
			h = dateTime.getUTCHours() + '',
			i = dateTime.getUTCMinutes() + ''

		if(h.length < 2) h = '0' + h
		if(i.length < 2) i = '0' + i

		return `${d} ${monthes[m]} ${y} ${h}:${i}`
	}
}

module.exports = DateTime