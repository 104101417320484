import types from './types'

export const show = (payload) => {
    return {
        type: types.CONFIRM_INPUT_SHOW,
        payload: payload
    }
}
export const hide = () => {
    return {
        type: types.CONFIRM_INPUT_HIDE
    }
}
export const ref = ref => {
    return {
        type: types.CONFIRM_INPUT_REF,
        payload: ref
    }
}
export const inputRef = ref => {
    return {
        type: types.CONFIRM_INPUT_FIELD_REF,
        payload: ref
    }
}