import { Children, useEffect } from 'react'
import { isArray, isStr } from '../services/isType'

import { ReactComponent as CheckmarkSvg } from '../assets/img/checkmark.svg'


function SelectCards({ children, validators, defaultValue, value, onEdit, onValid }){

	const editHandler = newValue => {
		onEdit(newValue)

		let newValid = true
		if(isArray(validators)){
			for(let validator of validators){
				const onValid = validator(newValue)
				if(onValid !== true){
					newValid = onValid
					break
				}
			}
		}
		onValid(newValid)
	}
	useEffect(() => {
		if(value === '' && defaultValue && isStr(defaultValue)) editHandler(defaultValue)
		else editHandler(value)
	}, [])


	return (
		<div className='select-cards'>
			{Children.map(children, child => {
				const className = child.props.className || ''
				const option = child.props.value
				const title = child.props.title
				const description = child.props.description
				const disabled = child.props.disabled
				return (
					<div className={`select-card${option === value ? ' select-card__selected' : ''} ${className}`} onClick={() => disabled !== true && editHandler(option)}>
						<div className='select-card__ico'></div>
						<div className='select-card__data'>
							<div>{title}</div>
							<div>{description}</div>
						</div>
						<div className='select-card__checkmark'><CheckmarkSvg /></div>
					</div>
				)
			})}
		</div>
	)

}

export default SelectCards