import reducers from '../reducers';
import middleware from '../middleware'
import { createStore, applyMiddleware } from 'redux';

export default function initStore() {
    const store = createStore( 
        reducers,
        middleware, 
    )
    return store
}