import types from './types'

export const show = (payload) => {
    return {
        type: types.ALERT_SHOW,
        payload: payload
    }
}
export const hide = () => {
    return {
        type: types.ALERT_HIDE
    }
}