import Color from '../services/Color'
import TG from '../TG'

import types from '../actions/types'

const defaultState = {
    showed: false,
    success: false,

    pageBg: null,
    pageBackState: null
}

function loader(state=defaultState, action) {    
    // For Debugger
    console.log('payload:', action);

    switch (action.type) {
        case types.LOADER_SHOW:
            const nowBg = TG.getBgColor()
            const nowBackState = TG.BackButton.getState()

            const newBg = new Color(nowBg)
            newBg.layerBlack(0.6)
            TG.setBgColor(newBg.getHex())
            TG.BackButton.show(() => {})

            document.body.style.overflow = 'hidden'

            return {
                ...state,
                showed: true,
                success: false,

                pageBg: nowBg,
                pageBackState: nowBackState
            }
        case types.LOADER_SUCCESS:
            return {
                ...state,
                success: true
            }
        case types.LOADER_HIDE:
            if(state.pageBg) TG.setBgColor(state.pageBg)
            if(state.pageBackState) TG.BackButton(state.pageBackState)

            document.body.style.overflow = 'auto'

            return {
                ...state,
                showed: false,

                pageBg: null,
                pageBackState: null
            }
        default:
            return state
    }
}

export default loader