import { useState } from 'react'
import { isFunc } from '../../services/isType'
import TelegramPage from '../../components/TelegramPage'
import TelegramForm from '../../components/TelegramForm'
import TelegramButton from '../../components/TelegramButton'
import SelectCards from '../../components/SelectCards'
import SelectCard from '../../components/SelectCard'
import Validators from '../../services/Validators'
import WebAPI from '../../WebAPI'

import { useDispatch } from 'react-redux'
import * as ErrorAction from '../../actions/errorAction'
import * as NotificationAction from '../../actions/notificationAction'
import * as UserAction from '../../actions/userAction'
import * as PageAction from '../../actions/pageAction'

import logoUsdtTrc20 from '../../assets/img/logo-usdt-trc20.svg'
import logoComingSoon from '../../assets/img/logo-coming-soon.svg'

function Deposit(){
	const dispatch = useDispatch()

	const [methodValue, setMethodValue] = useState('')
	const [methodValid, setMethodValid] = useState(true)


	const [sendingForm, setSendingForm] = useState(false)


	const submitHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		if(methodValid !== true){
			dispatch(NotificationAction.show(methodValid))
		}else{
			setSendingForm(true)

			if(methodValue === 'usdt_trc20'){
				WebAPI.Tron.getWallet()
				.then(wallet => {
					setSendingForm(false)

					if(wallet.object !== 'tron_wallet') throw { type: 'invalid_response' }
					dispatch(UserAction.setDepositWallet(wallet))
					dispatch(PageAction.setAccountPage('deposit_wallet'))
				})
				.catch(e => {
					setSendingForm(false)
					dispatch(ErrorAction.sendError(e))
				})
			}else dispatch(NotificationAction.show('Method not supported'))
		}
	}

	return (
		<TelegramPage className='page-deposit' bgColor='#1c1c1c' back={() => dispatch(PageAction.setAccountPage('balance'))}>
			<div className='deposit-title h24'>Select deposit method</div>
			<form className='form' onSubmit={submitHandler}>
				<SelectCards name='method' defaultValue='usdt_trc20'
					value={methodValue} onEdit={setMethodValue}
					valid={methodValid} onValid={setMethodValid}
					validators={[
						Validators.required('You must select a deposit method')
					]}
				>
					<SelectCard className='deposit-select-method__usdt-trc20' value='usdt_trc20' title='USDT (TRC-20)' description='Cryptocurrency' />
					<SelectCard className='deposit-select-method__coming-soon' value='_' title='More methods...' description='Coming soon' disabled={true} />
				</SelectCards>
				<TelegramButton text='CONTINUE' onClick={submitHandler} loading={sendingForm} />
			</form>
		</TelegramPage>
	)
}

export default Deposit