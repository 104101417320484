import axios from 'axios'
import qs from 'qs'

const Request = async (options) => {
	return new Promise((resolve, reject) => {
		axios(options)
		.then(response => {
			try{
				//alert('axios success')
				let data = response.data
				//alert(JSON.stringify(data))
				if(!data) throw new Error('Invalid Server response')
				if(data.error && data.description) throw new Error(data.description)

				if(data.status && data.status === 'ok') resolve(data.data)
				else throw new Error('Invalid Server response')
			}catch(e){
				reject({ type: 'error_text', text: e.message })
			}
		})
		.catch(error => {
			//alert('axios error')
			//alert(JSON.stringify(error.response.data))
			if(!error.response || !error.response.data) return alert(error.message) & reject(error)

			let response = error.response.data
			if(response && response.error && response.error === 'invalid_input_data'){
				if(response.fields) reject({ type: 'invalid_input_data', fields: response.fields })
				else if(response.description) reject({ type: 'invalid_input_data', description: response.description })
				else reject({ type: 'invalid_input_data', description: 'Invalid request data' })
			}
			else if(response && response.error && response.description) reject({ type: 'error_text', text: response.description })
			else reject({ type: 'invalid_response', description: 'Invalid response Err0', response })
		})
	})
}

let WebAPI = function(){
	let that = this
	let url = 'https://tronweb.mvc-project.site/api/v1'
	let accountToken = null

	let User = function(){
		const that = this

		that.auth = async telegram_instance => {
			let response = await Request({ method: 'post', url: url + '/auth', data: qs.stringify({ telegram_instance }) })
			return response
		}

		that.setToken = token => {
			accountToken = token
		}


		that.get = async () => {
			let response = await Request({ method: 'get', url: url + '/account', headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}

		that.update = async (first_name, last_name, email) => {
			let response = await Request({ method: 'post', url: url + '/account', data: qs.stringify({ first_name, last_name, email }), headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}

		that.withdraw = async elasticData => {
			let response = await Request({ method: 'post', url: url + '/withdraw', data: qs.stringify(elasticData), headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}


		that.getTransactions = async () => {
			let response = await Request({ method: 'get', url: url + '/transactions', headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}
	}
	User = new User()
	that.User = User

	let Cards = function(){
		const that = this

		that.get = async () => {
			let response = await Request({ method: 'get', url: url + '/cards', headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}

		that.order = async count => {
			let response = await Request({ method: 'post', url: url + '/cards', data: qs.stringify({ count }), headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}

		that.cancel = async id => {
			let response = await Request({ method: 'post', url: `${url}/cards/${id}/cancel`, headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}

		that.addTag = async (cardId, tagName) => {
			let response = await Request({ method: 'post', url: `${url}/cards/${cardId}/tags`, data: qs.stringify({ tag_name:tagName }), headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}
		that.editTag = async (cardId, tagId, tagName) => {
			let response = await Request({ method: 'post', url: `${url}/cards/${cardId}/tags/${tagId}`, data: qs.stringify({ tag_name:tagName }), headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}
		that.deleteTag = async (cardId, tagId) => {
			let response = await Request({ method: 'delete', url: `${url}/cards/${cardId}/tags/${tagId}`, headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}

		that.lock = async cardId => {
			let response = await Request({ method: 'post', url: `${url}/cards/${cardId}/lock`, headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}
		that.unlock = async cardId => {
			let response = await Request({ method: 'post', url: `${url}/cards/${cardId}/unlock`, headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}

		that.archive = async cardId => {
			let response = await Request({ method: 'post', url: `${url}/cards/${cardId}/archive`, headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}

		that.delete = async cardId => {
			let response = await Request({ method: 'delete', url: `${url}/cards/${cardId}`, headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}

		that.limit = async (cardId, limit) => {
			let response = await Request({ method: 'post', url: `${url}/cards/${cardId}/limit`, data: qs.stringify({ limit }), headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}

		that.transactions = async cardId => {
			let response = await Request({ method: 'get', url: `${url}/cards/${cardId}/transactions`, headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}
	}
	Cards = new Cards()
	that.Cards = Cards

	let Tron = function(){
		const that = this

		that.getWallet = async () => {
			let response = await Request({ method: 'get', url: url + '/tron/wallet', headers: { Authorization: `Bearer ${accountToken}` } })
			return response
		}
	}
	Tron = new Tron()
	that.Tron = Tron
	
}

WebAPI = new WebAPI()
export default WebAPI