import WebAPI from '../WebAPI'
import types from '../actions/types'

const defaultState = {
    startAuth: false,
    authorized: false,
    account: {},
    depositWallet: null,
    withdrawOrder: null,
    cardsLimitAmountNotified: null
}

function user(state=defaultState, action) {    
    
    switch (action.type) {
        case types.USER_START_AUTH:
            return {
                ...state,
                startAuth: true
            }
        case types.USER_SET_ACCOUNT:
            return {
                ...state,
                authorized: true,
                account: action.payload
            }
        case types.USER_SET_DEPOSIT_WALLET:
            return {
                ...state,
                depositWallet: action.payload
            }
        case types.USER_SET_WITHDRAW_ORDER:
            return {
                ...state,
                withdrawOrder: action.payload
            }
        case types.USER_SET_CARDS_LIMIT_AMOUNT_NOTIFIED:
            return {
                ...state,
                cardsLimitAmountNotified: action.payload
            }
        default:
            return state
    }

}

export default user