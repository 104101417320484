import { useState } from 'react'
import { isFunc, isStr } from '../../../services/isType'
import Validators from '../../../services/Validators'
import WebAPI from '../../../WebAPI'

import useConfirm from '../../../hooks/useConfirm'
import useConfirmInput from '../../../hooks/useConfirmInput'
import useAlert from '../../../hooks/useAlert'
import useLoader from '../../../hooks/useLoader'
import useCopy from '../../../hooks/useCopy'

import { useDispatch } from 'react-redux'
import * as ErrorAction from '../../../actions/errorAction'
import * as NotificationAction from '../../../actions/notificationAction'
import * as PageAction from '../../../actions/pageAction'

function CardLine({ data, dataList }){
	const Copy = useCopy()
	const Confirm = useConfirm()
	const ConfirmInput = useConfirmInput()
	const Alert = useAlert()
	const Loader = useLoader()

	const dispatch = useDispatch()

	const className = ['card-line']
	let icoName = '•0000'
	let title = 'VISA card #0000'
	let processing = false
	let tags = [{ id:'loading', name:'loading' }]
	let addTag = false
	let status = null
	let isArchived = false
	//Alert.show('')


	const [sendingCancel, setSendingCancel] = useState(false)
	const cancelHandler = async e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		Confirm.show('Confirm', 'Are you sure you want to cancel the process of creating the card?')
		.then(() => {
			cancel()
		})
		.catch(() => null)
	}
	const cancel = () => {
		setSendingCancel(true)

		WebAPI.Cards.cancel(data.id)
		.then(() => {
			setSendingCancel(false)

			dispatch(NotificationAction.show('The order has been canceled', 'success'))
			if(dataList && isFunc(dataList.refresh)) dataList.refresh()
		})
		.catch(e => {
			setSendingCancel(false)
			
			if(e && e.type === 'invalid_input_data' && isStr(e.description)) Alert.show(e.description)
            else dispatch(ErrorAction.sendError(e))
		})
	}


	const [cardState, setCardState] = useState(null)
	const updateCardData = newCardData => {
		for(let field in newCardData) data[field] = newCardData[field]
		setCardState(Math.random())
	}


	const getCardTitle = (brand, number) => {
		let brandName = ''
		switch(brand){
		case 'visa':
			brandName = 'VISA'
			break
		}

		return (brandName.length ? `${brandName} ` : '') + `card${number.length ? ` #${number.substr(-4)}` : ''}`
	}
	const getFormattedNumber = () => {
		const cardNumber = data.number
		return `${cardNumber.substr(0, 4)} ${cardNumber.substr(4, 4)} ${cardNumber.substr(8, 4)} ${cardNumber.substr(12, 4)}`
	}


	const clickHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		if(data.status === 'archived') return informationHandler()

		if(data && data.object === 'bank_card' && ['active', 'locked'].includes(data.status)){
			dispatch(PageAction.setShowedCard(data))
			dispatch(PageAction.setAccountPage('card'))
		}
	}


	const informationHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		if(data && data.object === 'bank_card' && ['archived'].includes(data.status)){
			const archivedCardInfo = <div>
				<div className='confirm-text'>Archived card number (tap for copy)</div>
				<div className='card-information__modal' onClick={e => {
					e.preventDefault()
					Copy(data.number, 'The card number has been copied')
				}}>
					<div className={`card-information__modal-ico ${data.brand}`}></div>
					<div className='card-information__modal-number'>{getFormattedNumber()}</div>
				</div>
			</div>
			const archivedCardButtons = <div className='modal-buttons'>
				<div className='btn red' onClick={e => {
					e.preventDefault()
					Alert.hide()

					Confirm.show('Confirm', 'The card will be permanently removed from your cards list')
					.then(() => {

						Loader.show()

						const cardId = data ? data.id : null
						WebAPI.Cards.delete(cardId)
						.then(() => {
							Loader.success()

							if(dataList && isFunc(dataList.refresh)) dataList.refresh()
							setTimeout(() => Loader.hide(), 600)
						})
						.catch(() => {
							Loader.hide()
							if(e && isStr(e.message)) Alert.show(e.message)
							else if(e && isStr(e.description)) Alert.show(e.description)
							else Alert.show('Invalid server response')
						})

					})
					.catch(() => {})
				}}>Delete card</div>
				<div className='confirm-cancel' onClick={e => {
					e.preventDefault()
					Alert.hide()
				}}>Cancel</div>
			</div>
			Alert.show({ modalClass: 'archived-card-modal',  text: 'Card info', description: archivedCardInfo, buttons: archivedCardButtons })
		}
	}


	const saveTag = async (tagId, tagName) => {
		Loader.show()
		const cardId = data ? data.id : null

		try{
			let newCardData
			if(typeof tagId !== 'string') newCardData = await WebAPI.Cards.addTag(cardId, tagName)
			else if(typeof tagName === 'string' && tagName.trim().length) newCardData = await WebAPI.Cards.editTag(cardId, tagId, tagName)
			else if(typeof tagName === 'string' && !tagName.trim().length) newCardData = await WebAPI.Cards.deleteTag(cardId, tagId)
			else throw new Error('Error processing tag')

			updateCardData(newCardData)
			Loader.success()
			setTimeout(() => Loader.hide(), 800)
		}catch(e){
			Loader.hide()
			if(e && isStr(e.message)) Alert.show(e.message)
			else if(e && isStr(e.description)) Alert.show(e.description)
			else Alert.show('Invalid server response')
		}
	}
	const editTagHandler = (e, tagId, tagName) => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()
		if(!['active', 'locked'].includes(data.status)) return;

		const editTagDescription = <div className='card-tag__modal'>
			<div className='confirm-text'>If you want to delete the tag, keep the field empty</div>
			<div className='card-tag__modal-ico'>
				<div><div className='card-tag__modal-ico-visa'></div></div>
				<div>#{data.number.substr(-4)}</div>
			</div>
		</div>
		ConfirmInput.show({ title:'Edit card tag', description:editTagDescription, placeholder:'Will be deleted', validators:[Validators.maxLength(25, 'The maximum length of tag is 25 characters')], defaultValue:tagName })
		.then(newTagName => {
			if(newTagName === tagName) return;
			saveTag(tagId, newTagName)
		})
		.catch(() => {})
	}
	const addTagHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		const addTagDescription = <div className='card-tag__modal'>
			<div className='confirm-text'>Enter the name of the new tag for card:</div>
			<div className='card-tag__modal-ico'>
				<div><div className='card-tag__modal-ico-visa'></div></div>
				<div>#{data.number.substr(-4)}</div>
			</div>
		</div>

		const addTagValidators = [
			Validators.required('Tag value is required'),
			Validators.maxLength(25, 'The maximum length of tag is 25 characters')
		]

		ConfirmInput.show({ title:'New card tag', description:addTagDescription, placeholder:'Max 25 chars', validators:addTagValidators, defaultValue:'' })
		.then(value => saveTag(null, value))
		.catch(() => {})
	}

	
	if(data === 'loading'){
		className.push('card-line__loading')
	}
	else if(data && data.object === 'bank_card' && data.status === 'processing'){
		icoName = '••••'
		title = getCardTitle(data.brand, '')
		processing = true
	}
	else if(data && data.object === 'bank_card' && data.status === 'active'){
		icoName = `•${data.number.substr(-4)}`
		title = getCardTitle(data.brand, data.number)

		tags = data.tags
		if(tags.length < 2) addTag = true

		status = <div className='card-line__status card-line__status-active'>Active</div>
	}
	else if(data && data.object === 'bank_card' && data.status === 'locked'){
		icoName = `•${data.number.substr(-4)}`
		title = getCardTitle(data.brand, data.number)

		tags = data.tags
		if(tags.length < 2) addTag = true

		status = <div className='card-line__status card-line__status-locked'>Locked</div>
	}
	else if(data && data.object === 'bank_card' && data.status === 'archived'){
		icoName = `•${data.number.substr(-4)}`
		title = getCardTitle(data.brand, data.number)
		isArchived = true

		tags = data.tags

		status = <div className='card-line__status card-line__status-archived'>Archived</div>
	}
	else throw new Error('Invalid card data')

	return (
		<div className={className.join(' ')}>
			<div className='card-line__ico' onClick={clickHandler}>
				<div className='card-line__ico-name'>{icoName}</div>
				<div className='card-line__ico-brand'></div>
			</div>
			<div className='card-line__data'>
				<div className='card-line__title'><div>{title}</div>{status}</div>
				{processing ? <div className='card-line__processing'>Processing...</div> :
					<div className='card-line__tags'>
						{tags.map(tag => {
							return <div key={`tag_${tag.id}`} className='card-line__tag' onClick={e => editTagHandler(e, tag.id, tag.name)}><div>{tag.name}</div></div>
						})}
						{addTag ? <div key='tag_new' className='card-line__tag-new' onClick={addTagHandler}><div>New tag</div></div> : null}
					</div>
				}
			</div>
			{processing ?
				(sendingCancel ? <div className='card-line__loader'></div> : <div className='card-line__cancel' onClick={cancelHandler}></div>) :
				isArchived ? <div className='card-line__archive-info' onClick={informationHandler} /> :
				<div className='card-line__arrow' onClick={clickHandler}></div>}
		</div>
	)

}

export default CardLine