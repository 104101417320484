export const isArray = array => {
	return array && typeof array === 'object' && array.constructor.name === 'Array'
}
export const isObj = object => {
	return object && typeof object === 'object' && object.constructor.name === 'Object'
}
export const isFunc = func => {
	return func && typeof func === 'function'
}
export const isStr = str => {
	return typeof str === 'string'
}
export const isNum = str => {
	return typeof str === 'number'
}


export const isDiv = element => {
	return element && isStr(element.type) && element.type === 'div'
}
export const isAmountField = element => {
	return element && element.type && typeof element.type === 'function' && element.type.name === 'AmountField'
}
export const isTelegramCryptoWalletField = element => {
	return element && element.type && typeof element.type === 'function' && element.type.name === 'TelegramCryptoWalletField'
}
export const isSelectComponent = element => {
	return element && element.type && typeof element.type === 'function' && element.type.name === 'Select'
}
export const isSelectComponentOption = element => {
	return element && element.type && typeof element.type === 'function' && element.type.name === 'SelectOption'
}