import { isStr } from '../services/isType'

import { useDispatch } from 'react-redux'
import * as NotificationAction from '../actions/notificationAction'

function useCopy(){
	const dispatch = useDispatch()

	const copy = (text, notificationMessage) => {
		const textField = document.createElement('textarea')
		textField.innerText = text
		document.body.appendChild(textField)
		textField.select()
		document.execCommand('copy')
		textField.remove()

		if(isStr(notificationMessage) && notificationMessage.length) dispatch(NotificationAction.show(notificationMessage, 'copied'))
	}

	return copy
}
export default useCopy