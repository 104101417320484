import TelegramPage from '../../components/TelegramPage'

import { useSelector, useDispatch } from 'react-redux'
import * as PageAction from '../../actions/pageAction'

import checkmarkFilledRoundedSvg from '../../assets/img/checkmark-filled-rounded_green.svg'

function WithdrawSuccess(){
	const dispatch = useDispatch()

	return (
		<TelegramPage className='page-withdraw-success' bgColor='#000' back={() => dispatch(PageAction.setAccountPage('balance'))}>
			<div className='withdraw-success-ico' style={{ backgroundImage: `url(${checkmarkFilledRoundedSvg})` }}></div>
			<div className='withdraw-success-title h24'>Successfully</div>
			<div className='withdraw-success-description t16'>Your withdrawal request has been successfully submitted and will be processed within 72 hours</div>
			<div className='btn blue' onClick={() => dispatch(PageAction.setAccountPage('balance'))}>OK</div>
		</TelegramPage>
	)

}

export default WithdrawSuccess