import AccountBalance from '../../components/AccountBalance'
import TelegramPage from '../../components/TelegramPage'
import AccountCard from '../../components/AccountCard'
import StakingCard from '../../components/StakingCard'
import Transactions from './Transactions'

import { useSelector, useDispatch } from 'react-redux'
import * as PageAction from '../../actions/pageAction'

import { ReactComponent as TopupSvg } from '../../assets/img/topup.svg'
import { ReactComponent as CardSvg } from '../../assets/img/card.svg'
import { ReactComponent as WithdrawSvg } from '../../assets/img/withdraw.svg'

function Balance(){
	const account = useSelector(state => state.user.account)
	const dispatch = useDispatch()
	
	return (
		<TelegramPage className='page-balance' bgColor='#1c1c1c'>
			<div className='balance-title t16'>Balance</div>
			<AccountBalance balance={account.balance} />
			<div className='balance-buttons'>
				<div className='balance-button' onClick={() => dispatch(PageAction.setAccountPage('deposit'))}>
					<div>
						<TopupSvg />
						<div>Top up</div>
					</div>
				</div>
				<div className='balance-button' onClick={() => dispatch(PageAction.setAccountPage('cards'))}>
					<div>
						<CardSvg />
						<div>My cards</div>
					</div>
				</div>
				<div className='balance-button' onClick={() => dispatch(PageAction.setAccountPage('withdraw'))}>
					<div>
						<WithdrawSvg />
						<div>Withdraw</div>
					</div>
				</div>
			</div>
			<AccountCard />
			<StakingCard />
			<Transactions />
		</TelegramPage>
	)

}


export default Balance