let Num = function (){
	let that = this

	that.amount = amount => {
		amount = parseFloat(amount)
		if(!isFinite(amount)) amount = 0

		let beforeDot = parseInt(amount),
			afterDot = parseInt(parseInt(amount * 100) % 100) + ''
		if(afterDot.length < 2) afterDot = '0' + afterDot
		return beforeDot + '.' + afterDot
	}
}

Num = new Num()
export default Num