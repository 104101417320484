import { combineReducers } from 'redux'
import UserReducer from './userReducer'
import ErrorReducer from './errorReducer'
import NotificationReducer from './notificationReducer'
import PageReducer from './pageReducer'
import ConfirmReducer from './confirmReducer'
import ConfirmInputReducer from './confirmInputReducer'
import AlertReducer from './alertReducer'
import LoaderReducer from './loaderReducer'

export default combineReducers({
    user: UserReducer,
    error: ErrorReducer,
    notification: NotificationReducer,
    page: PageReducer,
    confirm: ConfirmReducer,
    confirmInput: ConfirmInputReducer,
    alert: AlertReducer,
    loader: LoaderReducer
})