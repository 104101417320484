import React, { Component } from 'react'
import Registration from './account/Registration'
import Balance from './account/Balance'
import Cabinet from './account/Cabinet'
import Deposit from './account/Deposit'
import DepositWallet from './account/DepositWallet'
import Withdraw from './account/Withdraw'
import WithdrawConfirm from './account/WithdrawConfirm'
import WithdrawSuccess from './account/WithdrawSuccess'
import Cards from './account/Cards'
import CardsIssue from './account/Cards/issue'
import Card from './account/Cards/card'

import { useSelector } from 'react-redux'
import * as UserAction from '../actions/userAction'
import * as ErrorAction from '../actions/errorAction'

function Account(){
	const account = useSelector(state => state.user.account)
	const accountPage = useSelector(state => state.page.accountPage)

	if(account.registered){
		if(accountPage === 'cabinet') return <Cabinet />
		else if(accountPage === 'deposit') return <Deposit />
		else if(accountPage === 'deposit_wallet') return <DepositWallet />
		else if(accountPage === 'withdraw') return <Withdraw />
		else if(accountPage === 'withdraw_confirm') return <WithdrawConfirm />
		else if(accountPage === 'withdraw_success') return <WithdrawSuccess />
		else if(accountPage === 'cards') return <Cards />
		else if(accountPage === 'cards_issue') return <CardsIssue />
		else if(accountPage === 'card') return <Card />
		else return <Balance />
	}else return <Registration />
}

export default Account