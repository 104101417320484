import TelegramPage from '../../components/TelegramPage'
import TG from '../../TG'

import { useDispatch, useSelector } from 'react-redux'
import * as PageAction from '../../actions/pageAction'

function Cabinet(){
	const dispatch = useDispatch()
	const account = useSelector(state => state.user.account)

	return (
		<TelegramPage className='page-balance' bgColor='#1c1c1c' back={() => dispatch(PageAction.setAccountPage('balance'))}>
			<div className='cabinet-title t16'>Account</div>
			<div className='cabinet-account'>
				<div className='cabinet-account__avatar'></div>
				<div className='cabinet-account__name'>{account.first_name} {account.last_name}</div>
			</div>
			<div className='cabinet-card cabinet-card__email'>
				<div className='cabinet-card__ico'></div>
				<div className='cabinet-card__data'>
					<div className='cabinet-card__title'>
						<div>Email</div>
						<div className='cabinet-card__coming-soon'>Not confirmed</div>
					</div>
					<div className='cabinet-card__description'>{account.email}</div>
				</div>
			</div>
			<div className='cabinet-card cabinet-card__verification'>
				<div className='cabinet-card__ico'></div>
				<div className='cabinet-card__data'>
					<div className='cabinet-card__title'>
						<div>KYC verification</div>
						<div className='cabinet-card__coming-soon'>Coming soon</div>
					</div>
					<div className='cabinet-card__description'>For 5 cards per month</div>
				</div>
			</div>
			<div className='cabinet-card cabinet-card__2fa'>
				<div className='cabinet-card__ico'></div>
				<div className='cabinet-card__data'>
					<div className='cabinet-card__title'>
						<div>2FA password</div>
						<div className='cabinet-card__coming-soon'>Coming soon</div>
					</div>
					<div className='cabinet-card__description'>Set a password to log in</div>
				</div>
			</div>
			<div className='cabinet-close btn-line red' onClick={() => TG.close()}>Close the application</div>
		</TelegramPage>
	)

}
export default Cabinet