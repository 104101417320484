import { useState, useEffect } from 'react'

function useForm(){
	const [fields, setFields] = useState([])
	const [fieldsValue, setFieldsValue] = useState({})
	const [fieldsValid, setFieldsValid] = useState({})
	const [fieldsFocus, setFieldsFocus] = useState({})
	const fieldDefaultState = { value: '', valid: true, focus: false }


	const [canSubmit, setCanSubmit] = useState(false)
	useEffect(() => {
		let newCanSubmit = true
		for(let fieldName in fieldsValid){
			if(fieldsValid[fieldName] !== true){
				newCanSubmit = fieldsValid[fieldName]
			}
		}
		setCanSubmit(newCanSubmit)
	}, [fieldsValid])


	const getValue = fieldName => {
		if(fieldsValue[fieldName] === undefined) return fieldDefaultState.value
		return fieldsValue[fieldName]
	}
	const getValid = fieldName => {
		if(!fieldsValid[fieldName] === undefined) return fieldDefaultState.valid
		return fieldsValid[fieldName]
	}
	const getFocus = fieldName => {
		if(!fieldsFocus[fieldName] === undefined) return fieldDefaultState.focus
		return fieldsFocus[fieldName]
	}


	const initField = fieldName => {
		if(!fields.includes(fieldName)) setFields([...fields, fieldName])
	}
	const editHandler = (fieldName, value) => {
		initField(fieldName)
		if(fieldsValue[fieldName] === value) return;
		setFieldsValue({...fieldsValue, [fieldName]: value})
	}
	const validHandler = (fieldName, valid) => {
		initField(fieldName)
		if(fieldsValid[fieldName] === valid) return;
		setFieldsValid({...fieldsValid, [fieldName]: valid})
	}
	const focusHandler = (fieldName, focus) => {
		initField(fieldName)
		if(fieldsFocus[fieldName] === focus) return;
		setFieldsFocus({...fieldsFocus, [fieldName]: focus})
	}


	const focusInvalidField = () => {
		let foundInvalidField = false
		for(let fieldName of fields){
			if(!foundInvalidField && fieldsValid[fieldName] !== true){
				focusHandler(fieldName, true)
				foundInvalidField = true
			}else focusHandler(fieldName, false)
		}
	}


	return {
		canSubmit,
		value:getValue, onEdit:editHandler,
		valid:getValid, onValid:validHandler,
		focus:getFocus, onFocus:focusHandler,
		focusInvalidField
	}
}

export default useForm