import { isStr, isDiv } from '../services/isType'

import { useSelector } from 'react-redux'

function AlertModal(){
	const showed = useSelector(state => state.alert.showed)
	const modalClass = useSelector(state => state.alert.modalClass)
	const text = useSelector(state => state.alert.text)
	const description = useSelector(state => state.alert.description)
	const buttons = useSelector(state => state.alert.buttons)
	const cancelHandler = useSelector(state => state.alert.onCancel)


	return (
		<div className={`modal alert-modal ${showed === true ? 'show' : ''} ${isStr(modalClass) ? modalClass : ''}`}>
			<div>
				<div className='modal-content'>
					<div className='alert-text'>{text}</div>
					{isStr(description) ? <div className='confirm-text'>{text}</div> :
					 isDiv(description) ? description : null}
					{isDiv(buttons) ? buttons : <div className='modal-buttons'><div className='btn blue' onClick={cancelHandler}>OK</div></div>}
				</div>
			</div>
		</div>
	)

}

export default AlertModal