import { useEffect, useState, useRef } from 'react'
import QRCodeStyling from 'qr-code-styling'

import logoQrWalletSvg from '../assets/img/logo-usdt-trc20.svg'

function WalletQrCode({ generated, address }){

	const [options, setOptions] = useState({
		width: 220,
		height: 220,
		type: 'svg',
		data: null,
		image: logoQrWalletSvg,
		margin: 0,
		qrOptions: {
			typeNumber: 6,
			mode: 'Byte',
			errorCorrectionLevel: 'H'
		},
		imageOptions: {
			hideBackgroundDots: true,
			imageSize: 0.45,
			margin: 12,
			crossOrigin: 'anonymous',
		},
		dotsOptions: {
			color: '#fff',
			type: 'rounded'
    	},
    	backgroundOptions: {
	    	color: 'transparent'
	    },
    	cornersSquareOptions: {
    		color: '#fff',
    		type: 'extra-rounded'
    	},
    	cornersDotOptions: {
    		color: '#fff',
    		type: 'dot'
    	}
    })
    const [qrCode] = useState(new QRCodeStyling(options))
    const ref = useRef(null)

    useEffect(() => {
    	if (ref.current) {
    		qrCode.append(ref.current)
    	}
    }, [generated, qrCode, ref])

    useEffect(() => {
    	setOptions(options => ({
    		...options,
    		data: address
    	}))
    }, [generated, address])

    useEffect(() => {
    	if (!qrCode || !ref.current) return;
    	qrCode.update(options)
    }, [qrCode, options])

    if(generated === true) return <div ref={ref}></div>
	else return <div className='deposit-wallet-generating'><div></div></div>

}

export default WalletQrCode