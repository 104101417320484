import CardTransaction from './transaction'

function CardTransactionsLoading(){

	return (
		<>
			<CardTransaction data='loading' />
			<CardTransaction data='loading' />
			<CardTransaction data='loading' />
			<CardTransaction data='loading' />
		</>
	)

}
export default CardTransactionsLoading