import { useSelector } from 'react-redux'

function ConfirmModal(){
	const showed = useSelector(state => state.confirm.showed)
	const title = useSelector(state => state.confirm.title)
	const text = useSelector(state => state.confirm.text)
	const confirmHandler = useSelector(state => state.confirm.onConfirm)
	const cancelHandler = useSelector(state => state.confirm.onCancel)


	return (
		<div className={`modal confirm-modal ${showed === true ? 'show' : ''}`}>
			<div>
				<div className='modal-content'>
					<div className='confirm-title'>{title}</div>
					<div className='confirm-text'>{text}</div>
					<div className='btn blue' onClick={confirmHandler}>OK</div>
					<div className='confirm-cancel' onClick={cancelHandler}>Cancel</div>
				</div>
			</div>
		</div>
	)

}

export default ConfirmModal