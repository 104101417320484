import { useRef, useEffect, useState, forwardRef } from 'react'
import { isArray, isNum } from '../services/isType'
import Num from '../services/Num'

const AmountField = forwardRef(({ name, label, currency, amountMax, validators, value, onEdit, valid, onValid }, inputRef) => {
	
	const editHandler = (e) => {
		let newValue = ''
		if(inputRef.current) newValue = inputRef.current.value.replace(/,/ig, '.')

		if(!/^-?[0-9]*\.?[0-9]{0,2}$/ig.test(newValue)) return;
		onEdit(newValue)

		let newValid = true
		if(isArray(validators)){
			for(let validator of validators){
				let isValid = validator(newValue)
				if(isValid !== true){
					newValid = isValid
					break;
				}
			}
		}
		onValid(newValid)
	}
	useEffect(editHandler, [])


	const currencyRef = useRef(null)
	const [currencyWidth, setCurrencyWidth] = useState(0)
	useEffect(() => {
		if(!currencyRef.current) return;
		const newCurrencyWidth = currencyRef.current.getBoundingClientRect().width
		setCurrencyWidth(newCurrencyWidth)
	}, [currency, currencyRef])


	return (
		<div className={`field field-amount${valid !== true ? ' field-invalid' : ''}`}>
			<div className='field-amount__label'>
				{label ? <label htmlFor={name} className='field-label'>{label}</label> : null}
				{isNum(amountMax) && currency ? <label htmlFor={name} className='field-amount__max'><span>Max: </span><span>{Num.amount(amountMax)} {currency}</span></label> : null}
			</div>
			<div className='field-amount__body'>
				<input type='text' inputMode='decimal' placeholder='0' autoComplete='off' id={name} ref={inputRef} value={value} onInput={editHandler} style={{ width:`calc(100% - ${currencyWidth}px)` }} />
				{currency ? <label ref={currencyRef} htmlFor={name} className='field-amount__currency'>{currency}</label> : null}
			</div>
		</div>
	)

})

export default AmountField