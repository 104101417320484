import { isStr, isObj } from '../services/isType'

import { useDispatch } from 'react-redux'
import * as AlertAction from '../actions/alertAction'

function useAlert(){
	const dispatch = useDispatch()

	const show = (...args) => {
		return new Promise((resolve, reject) => {
			
			const cancelHandler = () => {
				dispatch(AlertAction.hide())
				resolve()
			}

			let modalClass = ''
			let text = ''
			let description = null
			let buttons = null

			if(args.length === 1 && isStr(args[0])) text = args[0]
			else if(args.length === 1 && isObj(args[0])){
				const data = args[0]
				modalClass = data.modalClass || ''
				text = data.text || ''
				description = data.description || null
				buttons = data.buttons || null
			}else throw new Error('Invalid Alert arguments')

			dispatch(AlertAction.show({modalClass, text, description, buttons, onCancel:cancelHandler}))
		})
	}

	const hide = () => {
		dispatch(AlertAction.hide())
	}

	return { show, hide }

}
export default useAlert