import { useRef, useState, useEffect } from 'react'
import { isFunc, isNum } from '../../../services/isType'
import TelegramPage from '../../../components/TelegramPage'
import CounterField from '../../../components/CounterField'
import TelegramButton from '../../../components/TelegramButton'
import Select from '../../../components/Select'
import SelectOption from '../../../components/SelectOption'
import LineField from '../../../components/LineField'
import Validators from '../../../services/Validators'
import WebAPI from '../../../WebAPI'

import { useDispatch, useSelector } from 'react-redux'
import * as PageAction from '../../../actions/pageAction'
import * as NotificationAction from '../../../actions/notificationAction'
import * as ErrorAction from '../../../actions/errorAction'
import * as UserAction from '../../../actions/userAction'

function CardsIssue(){
	const dispatch = useDispatch()

	const maxCards = useSelector(state => parseInt(state.user.account.cards_limit - state.user.account.cards_in_this_month))
	if(maxCards < 0) maxCards = 0
	const priceCard = 0

	const balance = useSelector(state => state.user.account.balance)
	const limitAmount = useSelector(state => state.user.account.cards_limit_amount)

	const countRef = useRef(null)
	const [countValue, setCountValue] = useState('')
	const [countValid, setCountValid] = useState(true)

	const [brandValue, setBrandValue] = useState('')
	const [brandValid, setBrandValid] = useState(true)
	const [brandShowed, setBrandShowed] = useState(false)

	const [sendingForm, setSendingForm] = useState(false)


	const blurFields = () => {
		if(countRef.current) countRef.current.blur()
	}


	const [canSubmit, SetCanSubmit] = useState(true)
	useEffect(() => {
		if(countValid !== true) SetCanSubmit(false)
		else SetCanSubmit(true)
	}, [countValid])


	const countValidators = [
		Validators.required('Count of cards is required'),
		Validators.amount('Enter the correct count of cards'),
		Validators.min(1, 'Minimum of 1 card to order')
	]
	if(isNum(maxCards)) countValidators.push(Validators.max(maxCards, `You can order a maximum of ${maxCards} card${maxCards === 1 ? '' : 's'} in this month`))


	let count = parseInt(countValue)
	if(!isFinite(count)) count = 0


	const submitHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		if(balance < limitAmount) return dispatch(NotificationAction.show(`The minimum balance for issuing cards is ${limitAmount}$`))

		if(countValid !== true){
			blurFields()
			if(countRef.current) countRef.current.focus()
			dispatch(NotificationAction.show(countValid))
		}else{
			blurFields()
			setSendingForm(true)

			WebAPI.Cards.order(countValue)
			.then(() => {
				return WebAPI.User.get()
			})
			.then(user => {
				setSendingForm(false)

				if(!user || user.object !== 'user') throw { type: 'invalid_response' }
				dispatch(UserAction.setAccount(user))
				dispatch(PageAction.setAccountPage('cards'))
				dispatch(NotificationAction.show(`Card${count === 1 ? '' : 's'} successful ordered. Processing will take up to 5 minutes, and a notification will be sent to you`, 'success'))
			})
			.catch(e => {
				setSendingForm(false)
				dispatch(ErrorAction.sendError(e))
			})
		}
	}


	return (
		<TelegramPage className='page-card-issue' bgColor='#1c1c1c' back={() => dispatch(PageAction.setAccountPage('cards'))}>
			<div className='card-issue-title h24'>New card</div>
			<form onSubmit={submitHandler} className='form'>
				<CounterField ref={countRef} name='count' label='Count cards'
					value={countValue} onEdit={setCountValue}
					valid={countValid} onValid={setCountValid}
					max={maxCards}
					maxlength={2}
					validators={countValidators}
				/>

				<div className='form-section'>
					<Select name='brand' label='Card brand' defaultValue='visa' className='select__bg'
						value={brandValue} onEdit={setBrandValue}
						valid={brandValid} onValid={setBrandValid}
						showed={brandShowed} onShowed={setBrandShowed}
						validators={[
							Validators.required('Card brand is required')
						]}
					>
						<SelectOption value='visa' content='VISA' />
					</Select>
					<LineField className='field-line__bg field-line__blue' label='Card issue price' value={`${priceCard} USD`}/>
					<LineField className='field-line__bg' label='Total price' value={`${priceCard*count} USD`}/>
				</div>

				<TelegramButton text={canSubmit ? 'SEND ORDER' : 'NEXT'} onClick={submitHandler} loading={sendingForm} />
			</form>
		</TelegramPage>
	)

}

export default CardsIssue