import React, { Component } from 'react'
import Config from '../config'
import TelegramPage from '../components/TelegramPage'

import {connect} from 'react-redux'
import * as ErrorAction from '../actions/errorAction'

class ErrorPage extends Component {
	render(){
		return (
			<TelegramPage className='page-error' bgColor='#000'>
				<div className='error-title h24'>{Config.appName}</div>
				<div className='error-text t18'>{this.props.errorText}</div>
			</TelegramPage>
		)
	}
}

const stateToProps = (state) => {
	return {
        errorText: state.error.errorText
    }
}

export default connect(stateToProps)(ErrorPage)