import { useEffect } from 'react'
import AfterLoadWindow from '../services/AfterLoadWindow'
import { isFunc } from '../services/isType'
import TG from '../TG'

import { useDispatch } from 'react-redux'
import * as PageAction from '../actions/pageAction'

function TelegramPage({ children, className, bgColor, back }){
	const dispatch = useDispatch()

	let loaded = () => {
		if(bgColor){
			TG.setBgColor(bgColor)
			document.body.style.background = bgColor
		}else document.body.style.background = '#000'

		dispatch(PageAction.mounted(Math.random() + ''))
	}
	useEffect(() => AfterLoadWindow(loaded), [])

	let backButton = () => {
		if(isFunc(back)) TG.BackButton.show(back)
		else TG.BackButton.hide()
	}
	useEffect(() => AfterLoadWindow(backButton), [back])
	
	const pageClasses = className && typeof className == 'string' && className.length ? className.split(' ') : []
	pageClasses.unshift('page')

	return (
		<div className={pageClasses.join(' ')}>
			<div>
				{children}
			</div>
		</div>
	)

}

export default TelegramPage