import { useDispatch, useSelector } from 'react-redux'
import * as LoaderAction from '../actions/loaderAction'

function useLoader(){
	const dispatch = useDispatch()

	const show = () => dispatch(LoaderAction.show())
	const success = () => dispatch(LoaderAction.success())
	const hide = () => dispatch(LoaderAction.hide())

	return { show, success, hide }

}
export default useLoader