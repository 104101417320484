import { useState, useEffect, useRef } from 'react'
import { isFunc, isStr, isNum } from '../../../services/isType'
import DataList from '../../../components/DataList'
import Validators from '../../../services/Validators'
import Num from '../../../services/Num'
import TelegramPage from '../../../components/TelegramPage'
import CardTransactionLine from './transaction'
import CardTransactionsLoading from './transactionsLoading'
import useCopy from '../../../hooks/useCopy'
import useLoader from '../../../hooks/useLoader'
import useAlert from '../../../hooks/useAlert'
import useConfirm from '../../../hooks/useConfirm'
import useConfirmInput from '../../../hooks/useConfirmInput'
import WebAPI from '../../../WebAPI'

import { useDispatch, useSelector } from 'react-redux'
import * as PageAction from '../../../actions/pageAction'
import * as ErrorAction from '../../../actions/errorAction'

function Card(){
	const dispatch = useDispatch()
	const Copy = useCopy()
	const Loader = useLoader()
	const Alert = useAlert()
	const Confirm = useConfirm()
	const ConfirmInput = useConfirmInput()


	const card = useSelector(state => state.page.showedCard)
	const cardNumber = `${card.number}`
	const last4 = cardNumber.substr(-4)
	const formattedNumber = `${cardNumber.substr(0, 4)} ${cardNumber.substr(4, 4)} ${cardNumber.substr(8, 4)} ${cardNumber.substr(12, 4)}`

	const cardMonth = `${card.month}`
	const cardYear = `${card.year}`
	const formattedExpirationDate = `${cardMonth} / ${cardYear}`

	const cvc = `${card.cvc}`

	const limitUsed = card.limit_used
	const limitTotal = card.limit_total
	const limit = card.limit
	if(limit !== 'infinite' && !isNum(limit)) throw new Error('Invalid card limit')


	const cardTransactions = useRef(null)
	const getTransactions = async () => {
		try{
            const cardTransactions = await WebAPI.Cards.transactions(card.id)
            if(cardTransactions.object !== 'list') throw new Error('Invalid transactions list')

            return cardTransactions.list
        }catch(e){
            dispatch(ErrorAction.sendError(e))
        }
	}


	const [cardState, setCardState] = useState('card')
	let cardBodyClass = ''
	let cardRequisitesClass = ''
	switch(cardState){
	case 'card':
		cardRequisitesClass = 'hide'
		break
	case 'show-requisites-1':
		cardBodyClass = 'start__hide'
		cardRequisitesClass = 'hide'
		break
	case 'show-requisites-2':
		cardBodyClass = 'hide'
		cardRequisitesClass = 'start__show'
		break
	case 'requisites':
		cardBodyClass = 'hide'
		cardRequisitesClass = ''
		break
	case 'hide-requisites-1':
		cardBodyClass = 'hide'
		cardRequisitesClass = 'start__hide'
		break
	case 'hide-requisites-2':
		cardBodyClass = 'start__show'
		cardRequisitesClass = 'hide'
		break
	}


	useEffect(() => {
		if(cardState === 'show-requisites-1') setTimeout(() => setCardState('show-requisites-2'), 400)
		if(cardState === 'show-requisites-2') setTimeout(() => setCardState('requisites'), 400)
		if(cardState === 'hide-requisites-1') setTimeout(() => setCardState('hide-requisites-2'), 400)
		if(cardState === 'hide-requisites-2') setTimeout(() => setCardState('card'), 400)
	}, [cardState])


	const showRequisitesHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		if(cardState === 'card') setCardState('show-requisites-1')
		if(cardState === 'requisites') setCardState('hide-requisites-1')
	}


	const lockHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		Loader.show()
		WebAPI.Cards.lock(card.id)
		.then(card => {
			if(!card || card.object !== 'bank_card') throw new Error('Invalid response')

			Loader.success()
			dispatch(PageAction.setShowedCard(card))
			setTimeout(() => Loader.hide(), 800)
		})
		.catch(e => {
			Loader.hide()
			if(isStr(e.message)) Alert.show(e.message)
			else if(isStr(e.description)) Alert.show(e.description)
			else Alert.show('The request has been canceled. Please try again later')
		})
	}
	const unlockHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		Loader.show()
		WebAPI.Cards.unlock(card.id)
		.then(card => {
			if(!card || card.object !== 'bank_card') throw new Error('Invalid response')

			Loader.success()
			dispatch(PageAction.setShowedCard(card))
			setTimeout(() => Loader.hide(), 800)
		})
		.catch(e => {
			Loader.hide()
			if(isStr(e.message)) Alert.show(e.message)
			else if(isStr(e.description)) Alert.show(e.description)
			else Alert.show('The request has been canceled. Please try again later')
		})
	}
	const archiveHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		Confirm.show('Confirm archiving', 'The card will be blocked forever. Archiving a created card will not reduce the limit of created cards in this month')
		.then(() => {

			Loader.show()
			WebAPI.Cards.archive(card.id)
			.then(card => {
				if(!card || card.object !== 'bank_card') throw new Error('Invalid response')

				Loader.success()
				setTimeout(() => {
					Loader.hide()
					dispatch(PageAction.setAccountPage('cards'))
				}, 800)
			})
			.catch(e => {
				Loader.hide()
				if(isStr(e.message)) Alert.show(e.message)
				else if(isStr(e.description)) Alert.show(e.description)
				else Alert.show('The request has been canceled. Please try again later')
			})

		})
		.catch(() => {})
	}


	const changeLimitHandler = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		const changeLimitDescription = <div className='card-tag__modal'>
			<div className='confirm-text'>Enter the spending limit on the card. If you want to delete the limit, keep the field empty</div>
			<div className='card-tag__modal-ico'>
				<div><div className='card-tag__modal-ico-visa'></div></div>
				<div>#{cardNumber.substr(-4)}</div>
			</div>
		</div>

		const changeLimitValidators = [
			Validators.amount('Invalid value'),
			Validators.max(1000000, 'The maximum limit can be 1 million $')
		]

		ConfirmInput.show({ type:'positive-amount', title:'Card limit', description:changeLimitDescription, placeholder:'Without limits', validators:changeLimitValidators, defaultValue:(limit === 'infinite' ? '' : Num.amount(limitTotal)) })
		.then(value => {
			
			Loader.show()
			WebAPI.Cards.limit(card.id, value)
			.then(card => {
				Loader.success()

				dispatch(PageAction.setShowedCard(card))
				setTimeout(() => Loader.hide(), 500)
			})
			.catch(e => {
				Loader.hide()
				if(e && isStr(e.message)) Alert.show(e.message)
				else if(e && isStr(e.description)) Alert.show(e.description)
				else Alert.show('Invalid server response')
			})

		})
		.catch(() => {})
	}
	

	return (
		<TelegramPage className='page-card' bgColor='#000' back={() => dispatch(PageAction.setAccountPage('cards'))}>
			<div className='card-container'>
				<div className={`card-body ${cardBodyClass}`} onClick={showRequisitesHandler}>
					<div className='card-body__number'>• {last4}</div>
					<div className='card-body__text'>Tap to see the full requisites</div>
				</div>
				<div className={`card-requisites ${cardRequisitesClass}`}>
				<div className='card-requisites__hide' onClick={showRequisitesHandler}>Hide</div>
					<div className='card-requisites__container'>
						<div className='card-requisite card-requisite__number'>
							<div onClick={(e) => e.preventDefault() & Copy(cardNumber, 'The card number has been copied')}>{formattedNumber}</div>
						</div>
						<div className='card-requisite'>
							<div onClick={(e) => e.preventDefault() & Copy(`${cardMonth}${cardYear}`, 'The card expiration date has been copied')}>{formattedExpirationDate}</div>
						</div>
						<div className='card-requisite'>
							<div onClick={(e) => e.preventDefault() & Copy(cvc, 'The card CVC has been copied')}>{cvc}</div>
						</div>
					</div>
				</div>
			</div>
			<div className='card-buttons'>
				{card.status === 'active' ?
					<div className='card-button card-button__lock' onClick={lockHandler}>
						<div>
							<div></div>
							<div>Lock</div>
						</div>
					</div>
				:
					<div className='card-button card-button__unlock' onClick={unlockHandler}>
						<div>
							<div></div>
							<div>Unlock</div>
						</div>
					</div>
				}
				
				<div className='card-button card-button__archive' onClick={archiveHandler}>
					<div>
						<div></div>
						<div>Archive</div>
					</div>
				</div>
			</div>
			<div className='card-limit'>
				<div className='card-limit__title'>
					<div>Card limit</div>
					<div className='card-limit__spend'>
						<div>{Num.amount(limitUsed)} USD</div>
						<div>/</div>
						{limit === 'infinite' ? <div className='card-limit__infinite'></div> : <div className='card-limit__total'>{Num.amount(limitTotal)} USD</div>}
					</div>
				</div>
				{limit === 'infinite' ?
					<div className='card-limit__set btn blue' onClick={changeLimitHandler}>Set card limit</div>:
					<div className='card-limit__change'>
						<div className='btn-line blue' onClick={changeLimitHandler}>Change the limit</div>
						<div className='card-limit__bar'><div style={{ width:`${limit*100/limitTotal}%` }}></div></div>
					</div>
				}
			</div>
			<div className='section card-transactions-section'>
	            <div className='section-top'>
	                <div className='section-title'>Card transactions</div>
	            </div>
	            <div className='section-content'>
	            	<DataList
	                	ref={cardTransactions}
	                    request={page => getTransactions()}
	                    line={<CardTransactionLine />}
	                    loading={<CardTransactionsLoading />}
	                    empty={<div className='transactions-empty t14'>There are no transactions on this card</div>}
	                />
	            </div>
	        </div>
		</TelegramPage>
	)

}
export default Card