import { Children, useEffect, useState, cloneElement, useCallback } from 'react'
import { isFunc, isAmountField, isSelectComponent } from '../services/isType'
import useForm from '../hooks/useForm'
import TG from '../TG'

import { useDispatch } from 'react-redux'
import * as NotificationAction from '../actions/notificationAction'
import * as ErrorAction from '../actions/errorAction'

function TelegramForm({ children }){
	const dispatch = useDispatch()
	const { canSubmit, value, valid, focus, onEdit, onValid, onFocus, focusInvalidField } = useForm()

	const formSubmitHandler = (e) => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		if(canSubmit !== true){
			focusInvalidField()
			dispatch(NotificationAction.show(canSubmit))
			TG.vibration()
		}else{
			alert('submit')
		}
	}


	return (
		<form className='form' onSubmit={formSubmitHandler}>
			{Children.map(children, child => {
				let resultChild
				const childName = child.props.name

				const editHandler = value => {
					if(isFunc(child.props.onEdit)) child.props.onEdit(value)
					console.log(childName, value)
					onEdit(childName, value)
				}

				if(isAmountField(child) || isSelectComponent(child)){
					resultChild = cloneElement(child, {...child.props,
						value: value(childName), onEdit: value => editHandler(value),
						valid: valid(childName), onValid: valid => onValid(childName, valid),
						focus: focus(childName), onFocus: focus => onFocus(childName, focus)
					})
				} else resultChild = child

				return resultChild
			})}
		</form>
	)

}

export default TelegramForm