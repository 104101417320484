import { useEffect } from 'react'
import TelegramPage from '../components/TelegramPage'
import AfterLoadWindow from '../services/AfterLoadWindow'
import WebAPI from '../WebAPI'
import TG from '../TG'

import { useDispatch, useSelector } from 'react-redux'
import * as ErrorAction from '../actions/errorAction'
import * as UserAction from '../actions/userAction'

import SplashscreenLogo from '../assets/img/logo-splashscreen.svg'

function Splashscreen(){
	const dispatch = useDispatch()
	const startAuth = useSelector(state => state.user.startAuth)

	const loaded = () => {
		setTimeout(() => {
			if(startAuth === true) return;
			dispatch(UserAction.startAuth())

			if(!TG.isVersion('7.0')) return dispatch(ErrorAction.sendError({ type: 'version_not_supported' }))

			const telegramInstance = TG.getInstance()
			WebAPI.User.auth(telegramInstance)
			.then(session => {
				if(!session || session.object !== 'session') throw { type: 'invalid_response' }

				const sessionToken = session.token
				WebAPI.User.setToken(sessionToken)

				const account = session.user
				dispatch(UserAction.setAccount(account))
			})
			.catch((e) => dispatch(ErrorAction.sendError(e)))
		}, 400)
	}
	useEffect(() => AfterLoadWindow(loaded), [])

	return (
		<TelegramPage className='page-loader' bgColor='#000'>
			<div style={{ backgroundImage: `url(${SplashscreenLogo}` }} className='page-loader-ico'></div>
		</TelegramPage>
	)

}

export default Splashscreen







/*const mapStateToProps = (state) => {
	return {
    	authorized: state.user.authorized
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        setSession: (session) => {
        	dispatch(UserAction.setSession(session))
        },
        setInited: () => {
        	dispatch(UserAction.setInited())
        },
        sendError: (e) => {
        	dispatch(ErrorAction.sendError(e))
        }
    }
}

const mergeProps = (stateProps, dispatchProps) => {
	return {
		...stateProps,
		...dispatchProps,
		auth: (tgData) => {
			if(stateProps.inited) return;
			dispatchProps.setInited()

			if(!TG.isVersion('7.0')) return dispatchProps.sendError({ type: 'version_not_supported' })
			let initData = TG.getInstance()

			WebAPI.auth(initData)
			.then((session) => {
				if(session.object != 'session') throw new Error('Invalid Session response')
				dispatchProps.setSession(session)
			})
			.catch(e => {
				dispatchProps.sendError(e)
			})
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Splashscreen)*/