import types from './types'

export const mounted = rand => {
	return {
        type: types.PAGE_MOUNTED,
        payload: rand
    }
}
export const setAccountPage = name => {
    return {
        type: types.SET_ACCOUNT_PAGE,
        payload: name
    }
}
export const setShowedCard = card => {
    return {
        type: types.SET_SHOWED_CARD,
        payload: card
    }
}