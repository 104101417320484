import DataList from '../../../components/DataList'
import TransactionsLoading from './loading'
import TransactionLine from './line'
import WebAPI from '../../../WebAPI'

import { useDispatch } from 'react-redux'
import * as ErrorAction from '../../../actions/errorAction'

function Transactions(){
    const dispatch = useDispatch()

    const getTransactions = async () => {
        try{
            const transactions = await WebAPI.User.getTransactions()
            if(transactions.object !== 'list') throw new Error('Invalid transactions list')

            return transactions.list
        }catch(e){
            dispatch(ErrorAction.sendError(e))
        }
    }

    return (
        <div className='section transactions-section'>
            <div className='section-top'>
                <div className='section-title'>Account transactions</div>
            </div>
            <div className='section-content'>
                <DataList
                    request={page => getTransactions()}
                    line={<TransactionLine />}
                    loading={<TransactionsLoading />}
                    empty={<div className='transactions-empty t14'>Your transactions will be here</div>}
                />
            </div>
        </div>
    )

}

export default Transactions