import { isFunc } from '../services/isType'
import Color from '../services/Color'
import TG from '../TG'

import types from '../actions/types'

const defaultState = {
    ref: null,
    inputRef: null,
    showed: false,
    type: null,
    title: null,
    description: null,
    placeholder: null,
    validators: null,
    defaultValue: null,
    onSubmit: null,
    onClose: null,

    pageBg: null,
    pageBackState: null
}

function confirmInput(state=defaultState, action) {    
    // For Debugger
    console.log('payload:', action);

    switch (action.type) {
        case types.CONFIRM_INPUT_SHOW:
            const nowBg = TG.getBgColor()
            const nowBackState = TG.BackButton.getState()

            const newBg = new Color(nowBg)
            newBg.layerBlack(0.6)
            TG.setBgColor(newBg.getHex())
            TG.BackButton.show(isFunc(action.payload.onClose) ? action.payload.onClose : () => {})

            document.body.style.overflow = 'hidden'

            return {
                ...state,
                showed: true,
                type: action.payload.type,
                title: action.payload.title,
                description: action.payload.description,
                placeholder: action.payload.placeholder,
                validators: action.payload.validators,
                defaultValue: action.payload.defaultValue,
                onSubmit: action.payload.onSubmit,
                onClose: action.payload.onClose,

                pageBg: nowBg,
                pageBackState: nowBackState
            }
        case types.CONFIRM_INPUT_HIDE:
            if(state.pageBg) TG.setBgColor(state.pageBg)
            if(state.pageBackState) TG.BackButton(state.pageBackState)

            document.body.style.overflow = 'auto'
        
            return {
                ...state,
                showed: false,

                pageBg: null,
                pageBackState: null
            }
        case types.CONFIRM_INPUT_REF:
            return {
                ...state,
                ref: action.payload
            }
        case types.CONFIRM_INPUT_FIELD_REF:
            return {
                ...state,
                inputRef: action.payload
            }
        default:
            return state
    }
}

export default confirmInput