import { useEffect, useState, useRef } from 'react'
import AfterLoadWindow from '../services/AfterLoadWindow'
import TG from '../TG'

import { useSelector, useDispatch } from 'react-redux'

function Notification(){
	const blockRef = useRef(null)
	const [viewportHeight, setViewportHeight] = useState(10000)
	const [blockHeight, setBlockHeight] = useState(10000)
	const [showed, setShowed] = useState(false)

	const changedHeight = () => {
		setViewportHeight(TG.getViewHeight())
		setBlockHeight(blockRef.current.getBoundingClientRect().height)
	}

	const loaded = () => {
		TG.onEvent('viewportChanged', changedHeight)
		changedHeight()
	}
	useEffect(() => {
		AfterLoadWindow(loaded)

		return () => {
			TG.offEvent('viewportChanged', changedHeight)
		}
	})

	
	const notificationText = useSelector(state => state.notification.text)
	const notificationType = useSelector(state => state.notification.type)
	const notificationDateClose = useSelector(state => state.notification.dateClose)
	const CheckShowed = () => {
		let nowDate = new Date(),
		isSettedText = false,
		isActiveDate = false

		if(notificationText && typeof notificationText === 'string' && notificationText.length) isSettedText = true
		if(notificationDateClose && typeof notificationDateClose === 'object' && notificationDateClose.constructor.name === 'Date' && notificationDateClose > nowDate) isActiveDate = true
		if(isSettedText && isActiveDate){
			if(!showed) setShowed(true)
			setTimeout(CheckShowed, 1000)
		}else if(showed) setShowed(false)
	}
	CheckShowed()

	const paddingBottom = 20

	const className = []
	if(notificationType === 'success') className.push('notification__success')
	if(notificationType === 'copied') className.push('notification__copied')

	let top = viewportHeight + 400
	if(showed) top = viewportHeight - blockHeight - paddingBottom

	return (
		<div ref={blockRef} className={`notification ${className.join(' ')}`} style={{ top: top+'px' }}>
			<div></div>
			<div>{notificationText}</div>
		</div>
	)
}

export default Notification