import types from './types'

export const show = (title, text, onConfirm, onCancel) => {
    return {
        type: types.CONFIRM_SHOW,
        payload: { title, text, onConfirm, onCancel }
    }
}
export const hide = () => {
    return {
        type: types.CONFIRM_HIDE
    }
}