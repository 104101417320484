import { forwardRef, useEffect } from 'react'
import { isArray } from '../services/isType'

const Input = forwardRef(({ name, label, placeholder, type, validators, noHelpers, value, onEdit, valid, onValid }, inputRef) => {
	let inputmode = 'text'
	if(['email'].includes(type)) inputmode = type

	let inputtype = 'text'
	if(['email'].includes(type)) inputtype = type


	const editHandler = (e) => {
		if(!inputRef.current) return;

		const newValue = inputRef.current.value
		onEdit(newValue)

		let newValid = true
		if(isArray(validators)){
			for(let validator of validators){
				const onValid = validator(newValue)
				if(onValid !== true){
					newValid = onValid
					break
				}
			}
		}
		onValid(newValid)
	}
	useEffect(editHandler, [inputRef])


	return (
		<div className={`field${valid !== true ? ' field-invalid' : ''}`}>
			{label ? <label htmlFor={name} className='field-label'>{label}</label> : null}
			<div className='field-body'>
				<input ref={inputRef} type={inputtype} id={name} placeholder={placeholder} autoComplete={noHelpers ? 'off' : 'on'} inputMode={inputmode} value={value} onInput={editHandler} />
			</div>
		</div>
	)

})

export default Input