import types from '../actions/types'

const defaultState = {
    mount: null,
    accountPage: null,
    showedCard: null
}

function page(state=defaultState, action) {    
    
    switch (action.type) {
        case types.PAGE_MOUNTED:
            return {
                ...state,
                mount: action.payload
            }
        case types.SET_ACCOUNT_PAGE:
            return {
                ...state,
                accountPage: action.payload
            }
        case types.SET_SHOWED_CARD:
            return {
                ...state,
                showedCard: action.payload
            }
        default:
            return state
    }

}

export default page