import { forwardRef, useEffect } from 'react'
import { isNum, isStr, isArray, isFunc } from '../services/isType'

const CounterField = forwardRef(({ name, label, max, maxlength, validators, value, onEdit, valid, onValid }, inputRef) => {

	const editHandler = newValue => {
		if(!isStr(newValue)){
			if(inputRef.current) newValue = inputRef.current.value
			else newValue = ''
		}

		if(!/^[0-9]*$/.test(newValue)) newValue = value
		newValue = newValue.length ? parseInt(newValue)+'' : ''
		if(isNum(maxlength) && newValue.length > maxlength) newValue = value
		onEdit(newValue)

		let newValid = true
		if(isArray(validators)){
			for(let validator of validators){
				let isValid = validator(newValue)
				if(isValid !== true){
					newValid = isValid
					break;
				}
			}
		}
		onValid(newValid)
	}
	useEffect(editHandler, [inputRef])


	const plus = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()

		let newValue = parseInt(value)
		if(!isFinite(newValue)) newValue = 0
		editHandler(newValue+1+'')
	}
	const minus = e => {
		if(e && isFunc(e.preventDefault)) e.preventDefault()
			
		let newValue = parseInt(value)
		if(!isFinite(newValue)) newValue = 0
		newValue -= 1
		if(newValue < 0) newValue = 0

		editHandler(newValue+'')
	}


	return (
		<div className='field-counter'>
			<div>
				<div className='field-counter__top'>
					<label htmlFor={name}>{label}</label>
					{isNum(max) || max === 'infinite' ? <div className='field-counter__max'><div>Max:</div>{max === 'infinite' ? <div className='field-counter__max-infinite'></div> : <div>{max}</div>}</div> : null}
				</div>
				<div className='field-counter__body'>
					<div className='field-counter__minus' onClick={minus}></div>
					<input ref={inputRef} type='input' inputMode='numeric' id={name} name={name} placeholder='0' value={value} onInput={editHandler} autoComplete='off' />
					<div className='field-counter__plus' onClick={plus}></div>
				</div>
			</div>
		</div>
	)

})

export default CounterField