import { useDispatch } from 'react-redux'
import * as PageAction from '../../../actions/pageAction'

function CardsEmpty(){
	const dispatch = useDispatch()

	return (
		<div className='cards-empty'>
			<div>No virtual cards were found</div>
			<div onClick={() => dispatch(PageAction.setAccountPage('cards_issue'))}>Issue card</div>
		</div>
	)

}

export default CardsEmpty