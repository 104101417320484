import types from './types'

export const startAuth = () => {
    return {
        type: types.USER_START_AUTH
    }
}
export const setAccount = account => {
    return {
        type: types.USER_SET_ACCOUNT,
        payload: account
    }
}
export const setDepositWallet = wallet => {
    return {
        type: types.USER_SET_DEPOSIT_WALLET,
        payload: wallet
    }
}
export const setWithdrawOrder = order => {
    return {
        type: types.USER_SET_WITHDRAW_ORDER,
        payload: order
    }
}
export const setCardsLimitAmountNotified = notifiedLimit => {
    return {
        type: types.USER_SET_CARDS_LIMIT_AMOUNT_NOTIFIED,
        payload: notifiedLimit
    }
}