import { cloneElement, useState, useEffect, forwardRef } from 'react'

import { useDispatch } from 'react-redux'
import * as ErrorAction from '../actions/errorAction'

const DataList = forwardRef(({ request, line, loading, empty }, dataListRef) => {
	const dispatch = useDispatch()

	const [sendingRequest, setSendingRequest] = useState(true)
	const [list, setList] = useState([])

	const sendRequest = () => {
		setSendingRequest(true)

		request(1)
		.then(list => {
			const newList = []

			for(let row of list){
				newList.push(cloneElement(line, {...line.pops, data:row, dataList:dataListRef ? dataListRef.current : null}))
			}
			setList(newList)
			setSendingRequest(false)
		})
		.catch(e => dispatch(ErrorAction.sendError(e)))
	}
	useEffect(sendRequest, [dataListRef])


	if(dataListRef) dataListRef.current = { refresh:sendRequest }
		

	return sendingRequest ? loading :
		   list.length ? (
		   	   <>
		   	       {list}
		   	   </>
		   ) : empty
})

export default DataList