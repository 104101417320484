import { isStr, isNum } from '../services/isType'

function Color(){
	const that = this
	let parsed = false
	let r, g, b

	
	that.set = () => {
		if(arguments.length === 3 && isNum(arguments[0]) && isNum(arguments[1]) && isNum(arguments[2])){
			parsed = true
			r = arguments[0]
			g = arguments[1]
			b = arguments[2]
		}else if(arguments.length === 1 && isStr(arguments[0])){
			let color = arguments[0]

			if(/^#[0-9a-z]{3}$/ig.test(color)){
				const parseColor = /^#([0-9a-z])([0-9a-z])([0-9a-z])$/ig.exec(color)
				parsed = true
				r = parseInt(`${parseColor[1]}${parseColor[1]}`, 16)
				g = parseInt(`${parseColor[2]}${parseColor[2]}`, 16)
				b = parseInt(`${parseColor[3]}${parseColor[3]}`, 16)
			}
			else if(/^#[0-9a-z]{6}$/ig.test(color)){
				parsed = true
				r = parseInt(`${color[1]}${color[2]}`, 16)
				g = parseInt(`${color[3]}${color[4]}`, 16)
				b = parseInt(`${color[5]}${color[6]}`, 16)
			}
		}
	}
	that.set(...arguments)


	that.getRGB = () => {
		if(!parsed) throw new Error('You cant get not-parsed color')
		return [r, g, b]
	}
	that.getHex = () => {
		if(!parsed) throw new Error('You cant get not-parsed color')

		let xr = r.toString(16)
		if(xr.length < 2) xr = `0${xr}`

		let xg = g.toString(16)
		if(xg.length < 2) xg = `0${xg}`

		let xb = b.toString(16)
		if(xb.length < 2) xb = `0${xb}`

		return `#${xr}${xg}${xb}`
	}


	let roundInt = num => {
		return Math.round(num)
	}


	// черный слой поверх текущего цвета
	that.layerBlack = opacity => {
		if(!parsed) throw new Error('You cant get not-parsed color')
		if(!isNum(opacity)) throw new Error('Opacity must be a number')

		opacity = 1 - opacity
		r = roundInt(r*opacity)
		g = roundInt(g*opacity)
		b = roundInt(b*opacity)
	}
}
export default Color