import { isStr } from '../../../services/isType'
import DateTime from '../../../services/DateTime'

function CardTransactionLine({ data, dataList }){
	
	let key = 'loading'
	const className = ['card-transaction']
	let title = 'loading loading'
	let date = 'loading'
	let amount = 'loading loading'
	let status = 'loading'


	const statuses = {
		processing: { text: 'Processing' },
		paid: { text: 'Successfull' }
	}

	
	if(data === 'loading'){
		className.push('card-transaction__loading')
	}
	else if(data && ['processing', 'paid'].includes(data.status)){
		key = data.id
		title = (isStr(data.description) && data.description.length ? data.description : 'Card transaction')
		date = (new DateTime(data.date)).toBeautifulStr()
		amount = `-${data.amount} USD`
		status = statuses[data.status].text
		className.push('card-transaction__processing')
	}
	else throw new Error('Invalid card transaction data')


	return (
		<div key={key} className={className.join(' ')}>
			<div className='card-transaction__data'>
				<div>{title}</div>
				<div>{date}</div>
			</div>
			<div className='card-transaction__right'>
				<div>{amount}</div>
				<div>{status}</div>
			</div>
		</div>
	)

}
export default CardTransactionLine